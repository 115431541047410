import { getConsentGiven, setConsentGiven, getPerformanceConsentGiven, setPerformanceConsentGiven, getConsentFired, setConsentFired, getConsentConfig, setConsentConfig, checkForConsentState } from './consentWorker.js';
import { gptModule } from './googletag.js';
import { auction } from '../../bidbarrel.js';
import { getConfig, setConfig } from '../../config.js';
import CONSTANTS from '../../constants.json';
import { eventEmitter } from '../../events.js';
import { exposureApi } from '../../exposureApi.js';
import { dom } from '../../global.js';
import { moduleManager } from '../../moduleManager.js';
import { errorReporting } from '../../services/errorReporting.js';
import { cookieStorageEngine } from '../../services/storage/engines/cookie.js';
import { localStorageEngine } from '../../services/storage/engines/localStorage.js';
import { setInitialPageTargeting } from '../../targeting.js';
import get from '../../utilities/helpers/get.js';
import isEmpty from '../../utilities/helpers/isEmpty.js';
// import omit from '../../utilities/helpers/omit.js';
import { logger } from '../../utilities/logger.js';
import { runQueue } from '../../utilities/queue.js';
import { renderScript } from '../../utilities/renderScript.js';

// import { isStagingEnv } from '../../utilities/environment.js';

const cmpLogger = logger({ name: 'consentManager', textColor: '#FFF', bgColor: '#550000' });
const { CONSENT } = CONSTANTS.MODULES;

let consentTimeoutId;
/**
 * Compliance module
 *
 * Ad Library integration with the compliance library
 *
 * @module consent
 * @private
 */

export const consentBase = (function cb() {
	/**
	 * List of script ids to prevent from rendering prior to consent
	 *
	 * @memberof consent
	 * @type {string[]}
	 * @private
	 */
	const consentGivenScriptIds = ['ix-identity', 'bb-sharethrough'];
	/**
	 * Run queue for when ads have been given the all-clear to process as per normal
	 *
	 * @memberof consent
	 * @type {Object}
	 * @private
	 */
	const consentGivenQueue = runQueue('consentGiven');
	consentGivenQueue.push(() => cmpLogger.logInfo(`Targeting ConsentGiven queue run`));
  /**
	 * Run queue for when analytics/performance tags have been given the all-clear to process as per normal
	 *
	 * @memberof consent
	 * @type {Object}
	 * @private
	 */
	const performanceConsentGivenQueue = runQueue('performanceConsentGiven');
	performanceConsentGivenQueue.push(() => cmpLogger.logInfo(`Performance ConsentGiven queue run`));
	/**
	 * Run queue for when the non-personalized ads flag has been set
	 *
	 * @memberof consent
	 * @type {Object}
	 * @private
	 */
	const npaFlagSetQueue = runQueue('npaFlagSet');
	npaFlagSetQueue.push(() => cmpLogger.logInfo(`NPAFlagSet queue run`));
	/**
	 * Queue cancellation item to ensure setInitialPageTargeting is not called too many times
	 *
	 * @memberof consent
	 * @type {Object}
	 * @private
	 */
	let cancelSetInitPageTargetingNpaItem;

	/**
	 * Function to setup global variables
	 *
	 * @memberof consent
	 * @private
	 */
	function bootstrap() {
		// Per the optanon docs we need to ensure googletag is on page and available before using the ad functionality
		dom().window.googletag = dom().window.googletag || {};
		dom().window.googletag.cmd = dom().window.googletag.cmd || [];

		moduleManager.gateways.register('consentGiven');
    moduleManager.gateways.register('performanceConsentGiven');
		moduleManager.gateways.register('npaFlagSet');
		moduleManager.gateways.onOpen(() => {
			// run npa queue
			npaFlagSetQueue.run(1);
		}, 'npaFlagSet');
		moduleManager.gateways.onOpen(() => {
			consentGivenQueue.run(getConsentGiven());
		}, 'consentGiven');
    moduleManager.gateways.onOpen(() => {
			performanceConsentGivenQueue.run(getPerformanceConsentGiven());
		}, 'performanceConsentGiven');
	}

	/**
	 * Adds the needed hooks for this module
	 *
	 * @memberof consent
	 * @private
	 */
	function applyHooks() {
		cmpLogger.logInfo(`ApplyHooks called`);
		renderScript.before((next, scriptConfig) => {
			if (consentGivenScriptIds.indexOf(scriptConfig.id) >= 0 || get(scriptConfig, 'filter.requiresConsent', false)) {
				consentGivenQueue.push(() => next(scriptConfig));
			} else {
				next(scriptConfig);
			}
		}, 1);
		cookieStorageEngine.setCookie.before((next, ...args) => {
			if (getConsentGiven()) {
				next(...args);
			} else {
				// consentGivenQueue.push(() => setCookie(...args));
				next(null);
			}
		}, 1);
		cookieStorageEngine.raw.setValue.before((next, ...args) => {
			if (getConsentGiven()) {
				next(...args);
			} else {
				// consentGivenQueue.push(() => setCookie(...args));
				next(null);
			}
		}, 1);
		cookieStorageEngine.getCookie.before((next, ...args) => {
			if (getConsentGiven()) {
				next(...args);
			} else {
				next(null);
			}
		}, 1);
		cookieStorageEngine.raw.getValue.before((next, ...args) => {
			if (getConsentGiven()) {
				next(...args);
			} else {
				next(null);
			}
		}, 1);
		localStorageEngine.raw.setValue.before((next, ...args) => {
			if (getConsentGiven()) {
				next(...args);
			} else {
				consentGivenQueue.push(() => localStorageEngine.raw.setValue(...args));
				// next(null);
			}
		}, 1);
		localStorageEngine.raw.getValue.before((next, ...args) => {
			if (getConsentGiven()) {
				next(...args);
			} else {
				next(null);
			}
		}, 1);
		setInitialPageTargeting.before((next, pageTargeting, forceRun = false) => {
			if (!getConsentGiven()) {
				consentGivenQueue.push(() => {
					cancelSetInitPageTargetingNpaItem();
					next(pageTargeting, true);
				});
				cancelSetInitPageTargetingNpaItem = npaFlagSetQueue.push(() => next(pageTargeting, forceRun));
			} else {
				next(pageTargeting, forceRun);
			}
		}, 1);
		gptModule.enableGoogletagServices.before((next) => {
			npaFlagSetQueue.push(() => next());
		}, 1);
		auction.before((next, units) => {
			npaFlagSetQueue.push(() => next(units));
		}, 1);
	}

	/**
	 * Handles the consent signal given by the CMP
	 *
	 * @memberof consent
	 * @private
	 */
	function consentHandler() {
		// cmpLogger.logInfo(`Targeting Consent Given`, allowed);
		// setConsentFired();
		const config = getConsentConfig();
		// const consent = setConsentGiven(allowed);
		const consent = getConsentGiven();
		// setConfig('consent', consent);

		if (consent) {
			if (moduleManager.gateways.isOpen('consentGiven') !== true) {
				moduleManager.gateways.open('consentGiven');
			}
			if (config.type === 'onetrust') {
        // this is handled by zdconsent script
				// eslint-disable-next-line func-names, no-undef
				dom().window.googletag.cmd.push(() => {
					// eslint-disable-next-line no-undef
					googletag.pubads().setPrivacySettings({ nonPersonalizedAds: false });
				});
				cmpLogger.logInfo(`NPA flag set to '0' - Personalized ads can be shown`);
			}
		}
		if (moduleManager.gateways.isOpen('npaFlagSet') !== true) {
			moduleManager.gateways.open('npaFlagSet');
		}

		eventEmitter.emit('consentReady', consent);
	}
	/**
	 * Method fired by CMP when Consent has been loaded
	 *
	 * @memberof consent
	 * @public
	 */
	function consentLoaded() {
		clearTimeout(consentTimeoutId);
    cmpLogger.logInfo(`Consent loaded. Removing consent timeout.`);
		checkForConsentState();
		consentHandler();
	}
	/**
	 * Method fired by module if consent times out
	 *
	 * @memberof consent
	 * @public
	 */
	function consentTimeout() {
		cmpLogger.logInfo(`Consent timeout expired.  Consent down or not loaded in page.`);
		// log message to adlib analytics
		const errorObj = new Error(`Consent timeout expired. Consent down or not loaded in page.`);
		errorReporting.report(errorObj);
		consentLoaded();
	}
	/**
	 * Handles all "setup" phase functionality
	 *
	 * @memberof consent
	 * @private
	 */
	function register() {
		getConfig('consentConfig', (newConfig) => {
			setConsentConfig(newConfig);

      if(newConfig.type === 'zdconsent') {
        // check for zdconsent object on the page, if not init object
        dom().window.zdconsent = dom().window.zdconsent || {run:[],cmd:[],useractioncomplete:[],analytics:[],functional:[],social:[]};
        // user has made a consent choice
        dom().window.zdconsent.useractioncomplete.push(() => {
          cmpLogger.logInfo(`zdconsent useractioncomplete run`);
          setConsentFired();
        });
        // user has agreed to targeting
        dom().window.zdconsent.cmd.push(() => {
          cmpLogger.logInfo(`zdconsent cmd run`);
          setConsentGiven(true);
          consentLoaded();
        });
// user has agreed to performance/analytics
dom().window.zdconsent.analytics.push(() => {
          cmpLogger.logInfo(`zdconsent analytics run`);
          setPerformanceConsentGiven(true);
        });
      }
		});
		cmpLogger.logInfo(`Register called. Consent is ${getConsentGiven()}`);
		setConfig('consent', getConsentGiven());
		bootstrap();
		applyHooks();
	}

	/**
	 * Module initialization
	 *
	 * @private
	 * @memberof consent
	 */
	function initialize() {
		cmpLogger.logInfo(`Initalize called`);
		let config = getConsentConfig();
		if (isEmpty(config)) {
			cmpLogger.logInfo(`Config empty, re-getting`);
			config = setConsentConfig(getConfig('consentConfig'));
			consentLoaded();
		}

		if (getConsentFired() === false) {
      cmpLogger.logInfo(`In init, consent not yet fired, setting timeout, ${config.timeout}`);
			// wait X ms timeout then fire consentLoaded();
			consentTimeoutId = setTimeout(() => {
				consentTimeout();
			}, config.timeout);
		}
	}

	// intercepting the OptanonWrapper function from the page to add our call when Consent is loaded
	// OptanonWrapper is the function that will be fired by OneTrust/Optanon when it's loaded or
	// any time the consent state is updated
	// eslint-disable-next-line func-names
	(function () {

    if(dom().window.zdconsent) {
      // I don't want to call these until the consentConfig.type is set to zdconsent
      // recognize that this means that consentConfig.type="onetrust" is broken if zdconent is on the page :/
      // cmpLogger.logInfo(`zdconsent found in the page.  OptanonWrapper function omitted.`);
      // dom().window.zdconsent.useractioncomplete.push(() => {
      //   cmpLogger.logInfo(`zdconsent useractioncomplete run`);
      //   setConsentFired();
      //   consentLoaded();
      // });
      // dom().window.zdconsent.cmd.push(() => {
      //   cmpLogger.logInfo(`zdconsent cmd run`);
      //   setConsentGiven(true);
      //   consentLoaded();
      // });
    } else {
      // eslint-disable-next-line func-names
      let oldOptanonWrapper = function () {};
      if (typeof dom().window.OptanonWrapper !== 'function') {
        // MLS: Convert the following to logError once all sites are off cbsioptanon and that is removed from this module
        cmpLogger.logInfo(
          `OptanonWrapper function not found.  Be sure to initialize the AdLibrary AFTER the consent manager and OptanonWrapper function are declared.\nDeclaring the OptanonWrapper function after the Ad Library can cause unexpected behavior.`,
        );
        // eslint-disable-next-line func-names
        dom().window.OptanonWrapper = function () {};
      }
      // eslint-disable-next-line no-undef
      oldOptanonWrapper = dom().window.OptanonWrapper;
      // eslint-disable-next-line no-undef, func-names
      dom().window.OptanonWrapper = function () {
        consentLoaded();
        oldOptanonWrapper();
      };
    }
	})();



	// Expose methods
	exposureApi.expose({
		consentLoaded,
	});
	return {
		consentLoaded,
		onConsentGiven: consentGivenQueue.push,
		onNpaFlagSet: npaFlagSetQueue.push,
		name: CONSENT,
		register,
		initialize,
	};
})();

export const consent = moduleManager.register(consentBase);
