module.exports = {
	api: {
		baseUrl: 'https://www.cnet.com/irb/lib/api',
		clientInfoUrl: 'https://www.cnet.com/irb/client-info',
		apiBase: {
			prod: 'https://www.cnet.com/irb/lib/api',
			stage: 'https://test.cnet.com/irb/lib/api',
		},
	},
	dfpPath: {
		property: 'cnet',
	},
	dynamicBidders: {
    amx: (existingBidderParams) => {
			if (!existingBidderParams.included) return null;
			return {
				tagId: 'Z2AKs8neN',
			};
		},
    openweb: (existingBidderParams) => ({ org: 'sp_UP8Xh26U', ...existingBidderParams }),
    openx: (existingBidderParamsArgs, unitConfig) => {
			const existingBidderParams = { ...existingBidderParamsArgs };
			if (!existingBidderParams.included) return null;

			if (!existingBidderParams.delDomain) {
				existingBidderParams.delDomain = 'ziffdavis-d.openx.net';
			}
			existingBidderParams.unit = unitConfig.isVideo || unitConfig.allowedTypes.video ? '561766420' : '561766419';

			return existingBidderParams;
		},
		// magnite
		rubicon: (existingBidderParams) => {
			if (!existingBidderParams.included) return null;
			return {
				siteId: '572406',
				accountId: 11576,
			};
		},
	},
	geo: {
		enabled: true,
	},
  intentIQ: {
		enabled: true,
		params: {
			domainName: 'cnet.com',
		},
	},
	prebid: {
		enabledRegions: ['us', 'emea', 'apac'],
		ids: {
			// identityLink: {
			//   enabled: true,
			//   template: true,
			//   userSyncConfig: {
			//       params: {
			//           pid: "13332"
			//       }
			//   }
			// },
			liveIntent: {
				userSyncConfig: {
					params: {
						publisherId: '895',
            scriptUrl: 'https://b-code.liadm.com/a-0168.min.js',
					},
				},
			},
			unifiedId: {
				enabled: true,
			},
		},
		pbjsConfig: {
			floors: {
				enabled: true,
				data: {
					schema: {
						delimiter: '|',
						fields: ['topAdUnit', 'fold', 'mediaType'],
					},
					values: {
						'/22309610186/aw-cnet|atf|*': 2.0,
						'/22309610186/aw-cnet|*|*': 0.5,
						'/22309610186/maw-cnet|*|*': 1.0,
						'/22309610186/aw-cnet|*|video': 1.0,
						'/22309610186/maw-cnet|*|video': 1.0,
						'/22309610186/vaw-cnet|*|*': 1.0,
						'*|*|video': 1.0,
					},
				},
			},
			ortb2: {
				site: {
					cat: ['IAB19', 'IAB22', 'IAB13'],
					cattax: 1,
				},
				user: {},
			},
		},
	},
	targeting: {
		seats: {
			session: 4,
			subsession: 6,
		},
	},
	timeouts: {
		bidder: 1000,
	},

	// Note that CNET uses a function which is setup on site-side to generate the incremenatal names
	// ,
	// generateIncrementalUnitCode: (templateCode, increment, template) => {
	//     if(template && template.isVideo){
	//         return templateCode
	//     }
	//     return templateCode + (1 < increment ? "-" + increment : '');
	// }
};
