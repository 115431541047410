const standardPriceBuckets = [
	{
		precision: 2,
		min: 0,
		max: 3,
		increment: 0.01,
	},
	{
		precision: 2,
		max: 8,
		increment: 0.05,
	},
	{
		precision: 2,
		max: 20,
		increment: 0.5,
	},
	{
		precision: 2,
		max: 35,
		increment: 1.0,
	},
];

const videoPriceBuckets = [
	{
		precision: 2,
		max: 10,
		increment: 0.25,
	},
	{
		precision: 2,
		max: 25,
		increment: 0.5,
	},
	{
		precision: 2,
		max: 50,
		increment: 1,
	},
];
const TREATMENT_RATE = 0.95;
let liModuleEnabled = Math.random() < TREATMENT_RATE;

if (typeof window !== 'undefined') {
	// eslint-disable-next-line no-undef
	window.liTreatmentRate = TREATMENT_RATE;
	// eslint-disable-next-line no-undef
	if (typeof window.liModuleEnabled !== 'undefined') {
		// eslint-disable-next-line no-undef
		liModuleEnabled = window.liModuleEnabled;
	} else {
		// eslint-disable-next-line no-undef
		window.liModuleEnabled = liModuleEnabled;
	}
}
module.exports = {
	adrefresh: {
		enabled: true,
		minDispTimeDFP: 20000,
		minDispTimeADX: 30000,
		minSinceActive: 4000,
		maxInactive: 120000,
		minViewArea: 50,
		respectRoadblocks: true,
		minTimeHouse: 15000,
		minTimeADX: 30000,
		minTimeOX: 15000,
		minTimeDefault: 30000,
		houseAdvertiserIds: [5009920773],
		oxAdvertiserIds: [5024616868, 5022645737],
		adxAdvertiserIds: [5024496911],
		refreshSponsorships: false,
		sponsorshipLineIds: [
			6832891151, 6886459570, 6887607563, 6887608304, 6886341762, 6887878959, 6890461159, 6893546309, 6892502608,
			6893577266, 6889785105, 6892358050, 6895843388, 6895952570, 6895467019, 6892829952, 6892830606, 6892915398,
			6819417299, 6819417308, 6897487412, 6893855682, 6893976435, 6896655358, 6897641648, 6898420058, 6895780740,
			6899495228, 6895831389, 6898598042, 6898598348, 6902908286, 6899200482, 6899386986, 6903238976, 6902172394,
			6899806482, 6900274971, 6892308204, 6894908281, 6894932089, 6904960763, 6903969814, 6894943434, 6898598345,
			6904410432, 6905331297, 6908073355, 6905397330, 6910794203, 6910800905, 6909745576, 6907069677, 6909691651,
			6909691666, 6910806452, 6910222786, 6910439956, 6892357813, 6892357891, 6912793849, 6913112344, 6896346147,
			6914840086, 6912475611, 6894932827, 6895943483, 6896727265, 6896726539, 6921661819, 6922763519, 6918985593,
			6921805390, 6922289968, 6922310584, 6919710651, 6919752621, 6922432783, 6923517581, 6899069440, 6900047906,
			6894943653, 6894943800, 6926608760, 6926668442, 6922812876, 6926667323, 6926684870, 6925544389, 6925544137,
			6922828707, 6922828938, 6922828941, 6925544134, 6922909431, 6922982055, 6923792397, 6923794017, 6927628523,
			6926543167, 6927650852, 6923836977, 6927664931, 6926578036, 6923879475, 6923908209, 6924844512, 6927692566,
			6930505654, 6928087824, 6931875761, 6930842356, 6931791964, 6933068363, 6929307552, 6931225815, 6934813636,
			6889785246, 6889785312, 6901355247, 6910199898, 6912872770, 6934292323, 6934292350, 6934292866, 6934293088,
			6939373136, 6938231002, 6940213196, 6935594547, 6938562230, 6939358739, 6937613217, 6938039931, 6938044530,
			6938193330, 6940887118, 6943415656, 6949003901, 6945235800, 6945869223, 6946300272, 6949009867, 6947043069,
			6947387205, 6947390091, 6951842597, 6951848081, 6948090717, 6950935666, 6894945261, 6894945333, 6948858648,
			6951856012, 6951702453, 6954542530, 6954554311, 6951799899, 6955609598, 6955100593, 6952345173, 6956136719,
			6952662471, 6957109601, 6953306574, 6954279297, 6956984089, 6957105496, 6957055123, 6958213361, 6897646792,
			6897646957, 6957480867,
		],
		noRefreshLineIds: [6704325809, 6704825445, 6707960777, 6745404447, 6745404459, 6747584806, 6784009433],
		noRefreshAdvertiserIds: [],
	},
	amazon: {
		pubID: 3158,
		enabled: true,
		adServer: 'googletag',
		regionConfig: {},
		allowedSizes: [
			[5, 5],
			[6, 6],
			[300, 250],
			[320, 50],
			[728, 90],
			[970, 250],
			[300, 600],
		],
	},
	analytics: {
		bidbarrel: [
			{
				id: 'rv-bidbarrel-core',
				protected: true,
				transport: 'beacon',
				frequency: 15000,
				session: { reportingPercentage: 100 },
				failThreshold: 3,
				url: '/event',
			},
		],
	},
	analyticsTimings: {
		utag: true,
		allowDuplicates: {},
		timeout: 60000,
		sequences: [
			['event-adlib-init', 'event-adlib-first-auction'],
			['event-adsrvr-first-req', 'event-first-render', 'event-first-imp'],
		],
		scripts: {
			'script-gpt': {
				matchPart: 'securepubads.g.doubleclick.net/tag/js/gpt.js',
				record: {
					slug: 'script-gpt',
					name: 'Time to GPT',
				},
			},
			'script-pubads': {
				matchPart: 'pubads_impl.js',
				record: {
					slug: 'script-pubads',
					name: 'Time to pubads_impl',
				},
			},
			'script-adlib': {
				matchPart: '(bidbarrel-[A-z]*(-rv)?)+(.*)(.min)?(.js)',
				record: {
					slug: 'script-adlib',
					name: 'Time to Ad Library',
				},
			},
			'scripts-cbsoptanon': {
				matchPart: '/a/privacy/optanon/optanon-',
				record: {
					slug: 'script-cbsoptanon',
					name: 'Time to CMP',
				},
			},
			'scripts-onetrust': {
				matchPart: 'otSDKStub.js',
				record: {
					slug: 'script-onetrust',
					name: 'Time to OneTrust',
				},
			},
			'scripts-cohesion': {
				matchPart: '(cohesion-[A-z]*)+(.min)?(.js)',
				record: {
					slug: 'script-cohesion',
					name: 'Time to Cohesion',
				},
      },
			'scripts-intentiq': {
				matchPart: 'IIQUniversalID.js',
				record: {
					slug: 'script-intentiq',
					name: 'Time to IntentIQ',
				},
			},
		},
		events: {
			initialize: {
				once: true,
				record: {
					slug: 'event-adlib-init',
					name: 'Time to Ad Library Initialize',
				},
			},
			auction: {
				once: true,
				record: {
					slug: 'event-adlib-first-auction',
					name: 'Time to First Ad Library Auction',
				},
			},
			requested: {
				once: true,
				event: {
					gpt: true,
					name: 'slotRequested',
				},
				record: {
					slug: 'event-adsrvr-first-req',
					name: 'Time to First Ad Server Request',
				},
			},
			rendered: {
				once: true,
				event: {
					gpt: true,
					name: 'slotRenderEnded',
				},
				record: {
					slug: 'event-first-render',
					name: 'Time to First Ad Render',
				},
			},
			viewable: {
				once: true,
				event: {
					gpt: true,
					name: 'impressionViewable',
				},
				record: {
					slug: 'event-first-imp',
					name: 'Time to First Viewable Impression',
				},
			},
      // adDensityAvailable: {
      //   once: true,
      //   record: {
			// 		slug: 'ad-density',
			// 		name: 'Time to Ad Density available',
			// 	},
      // },
			enrichmentFired: {
				once: true,
				record: {
					slug: 'event-enrichment-fired',
					name: 'Time to Enrichment request fired',
				},
			},
			enrichmentReturned: {
				once: true,
				record: {
					slug: 'event-enrichment-returned',
					name: 'Time to Enrichment request returned',
				},
			},
      intentiqLoaded: {
				once: true,
				record: {
					slug: 'event-intentiq-loaded',
					name: 'Time to IntentIQ file loaded',
				},
			},
			intentiqReady: {
				once: true,
				record: {
					slug: 'event-intentiq-ready',
					name: 'Time to IntentIQ IDs added',
				},
			},
			consentReady: {
				once: true,
				record: {
					slug: 'event-consent-ready',
					name: 'Time to consent ready',
				},
			},
			tagularReady: {
				once: true,
				record: {
					slug: 'event-tagular-ready',
					name: 'Time to Tagular Ready Fired',
				},
			},
      zdCoreReady: {
        once: true,
        record: {
          slug: 'event-zd-core-ready',
          name: 'Time to ZD Core Ready Fired',
        },
      },
		},
		// For Bidders if we'd like to measure their requests: https://github.com/prebid/header-bidder-expert/blob/master/src/js/definitions/calls.js
	},
  api: {
		baseUrl: 'https://www.cnet.com/irb/lib/api',
    clientInfoUrl: 'https://www.cnet.com/irb/client-info',
		apiBase: {
			prod: 'https://www.cnet.com/irb/lib/api',
			stage: 'https://test.cnet.com/irb/lib/api',
		},
		version: 1,
		fetchOptions: {
			useVersion: true,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		},
	},
	autoPriority: {
		enabled: true,
		refresh: true,
		bids: true,
	},
	blockthrough: {
		enabled: false,
		scriptUrl: 'https://btloader.com/tag?o=6209001497821184&upapi=true',
	},
	bootstrap: {
		renderScripts: [],
	},
	confiant: {
		badAdRefresh: true,
	},
	cookie: {
		defaultOptions: {
			path: '/',
		},
		dailySession: {
			cookieName: '_BB.d',
			defaultOptions: {
				expires: 'midnightEst',
			},
			deserialize: {
				match: (val) => val && val.indexOf('|') >= 0,
				process: (val) => {
					if (!val) return { firstpg: undefined, ftag: undefined, ttag: undefined, pv: undefined };
					const [firstpg, ftag, ttag, pv] = val.split('|');
					// eslint-disable-next-line no-self-compare
					const adjustedPv = parseInt(pv, 10) === parseInt(pv, 10) ? parseInt(pv, 10) : pv;
					return { firstpg, ftag, ttag, pv: adjustedPv };
				},
			},
			serialize: {
				match: (val) => !!val,
				process: ({ firstpg, ftag, ttag, pv }) => [firstpg, ftag, ttag, pv].join('|').replace(/undefined/gm, ''),
			},
		},
		browserSession: {
			cookieName: '_BB.bs',
			deserialize: {
				match: (val) => val && val.indexOf('|') >= 0,
				process: (val) => {
					if (!val) return { session: undefined, subses: undefined };
					const [session, subses] = val.split('|');
					return { session, subses };
				},
			},
			serialize: {
				match: (val) => !!val,
				process: ({ session, subses }) => [session, subses].join('|').replace(/undefined/gm, ''),
			},
		},
		enrichment: {
			cookieName: '_BB.enr',
			defaultOptions: {
				expires: 'midnightEst',
			},
		},
		gaClientId: {
			cookieName: '_ga',
			readOnly: true,
		},
		aamUuid: {
			cookieName: 'aam_uuid',
			readOnly: true,
		},
		seg: {
			cookieName: 'aamgam',
			readOnly: true,
			deserialize: {
				match: (val) => !!val,
				process: (val) => decodeURI(val).replace('segid=', '').split(','),
			},
			serialize: {
				match: (val) => !!val,
				process: (val) => encodeURI(`segid=${val.join(',')}`),
			},
		},
	},
	debug: {
		consoleMethods: {
			error: true,
			warn: true,
		},
	},
	dfpPath: {
		region: 'aw',
		network: '22309610186',
		device: '',
	},
	dfpPathDevices: {
		desktop: '',
		web: '',
		mweb: 'm',
		mobile: 'm',
		app: 'app',
		m: 'm',
		application: 'app',
	},
	dynamicTargeting: {
		rules: [
			{
				name: 'Slot Name String',
				matcher: () => true, // (unit) => !unit.isVideo || !unit.allowedTypes.video,
				key: 'sl',
				level: 'unit',
				hook: 'processAdRequest',
				getValue: (unit, context) => {
					const slFlags = {
						LL: unit.isLazyLoaded(),
					};
					const slResultArr = [];
					// eslint-disable-next-line no-restricted-syntax
					for (const targetingValue in slFlags) {
						if (Object.prototype.hasOwnProperty.call(slFlags, targetingValue)) {
							const shouldInclude = slFlags[targetingValue];
							if (shouldInclude) {
								slResultArr.push(targetingValue);
							}
						}
					}

					if (context.bidderTimeout) {
						slResultArr.push(`T-${context.bidderTimeout}`);
					}

					if (typeof context.seamlessIndicator !== 'undefined') {
						slResultArr.push(`SI-${context.seamlessIndicator}`);
					}

					if (unit.isVideo || unit.allowedTypes.video) {
						// eslint-disable-next-line prefer-template
						return unit.originalCode + (slResultArr.length > 0 ? '?' + slResultArr.join('%7C') : '');
					}
					// eslint-disable-next-line prefer-template
					return unit.originalCode + (slResultArr.length > 0 ? '?' + slResultArr.join('|') : '');
				},
			},
			{
				name: 'Slim Slot Name String', // slot name before the ? so that DoubleVerify can use it
				matcher: () => true,
				key: 'sldv',
				level: 'unit',
				hook: 'processAdRequest',
				getValue: (unit) => unit.originalCode,
			},
			{
				name: 'Adobe Segment IDs',
				matcher: () => true,
				key: 'seg',
				level: 'page',
				hook: 'processAdRequest',
				cacheResult: true,
				getValue: (context) => context.getCookie('seg') || null,
			},
			{
				name: 'Adobe Audience Manager ID',
				matcher: () => true,
				key: 'aamid',
				level: 'page',
				hook: 'processAdRequest',
				cacheResult: true,
				getValue: (context) => context.getCookie('aamUuid') || null,
			},
			{
				name: 'Impression ID',
				matcher: () => true,
				key: 'iid',
				level: 'unit',
				hook: 'setBidTargeting',
				// eslint-disable-next-line no-unused-vars
				getValue: (unit, context) => `unit=${unit.code}|${unit.generateImpressionId(['vguid', 'pv', 'hb_adid', 'amzniid'])}`,
			},
			{
				name: 'Live Intent Module Indicator',
				matcher: () => true,
				key: 'li-module-enabled',
				level: 'page',
				hook: 'processAdRequest',
				cacheResult: false,
        getValue: (context) => ((context.property === 'cnet' || context.property === 'zdnet') && liModuleEnabled ? ['t1'] : ['t0']),
			},
      {
				name: 'Live Intent liact',
				matcher: () => true,
				key: 'liact',
				level: 'page',
				hook: 'processAdRequest',
				cacheResult: false,
				getValue: (context) => ((context.property === 'cnet' || context.property === 'zdnet') && liModuleEnabled ? ['on'] : ['off']),
			},
		],
		units: {},
	},
   // A reminder: Changes in dynamicBidders will require a numbered deploy.  These will NOT go live with a remote config deploy.
	dynamicBidders: {
		/* eslint-disable no-unused-vars */
		appnexus: (existingBidderParamsArgs, { isVideo, getLatestVideoSpec }) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (isVideo) {
				const video = getLatestVideoSpec();
				existingBidderParams = {
					...existingBidderParams,
					video: {
						mimes: video.mimes,
						minduration: video.minduration,
						maxduration: video.maxduration,
						startdelay: video.startdelay,
						skippable: video.skip === 1,
						playback_method: ['', 'auto_play_sound_on', 'auto_play_sound_off', 'click_to_play'].filter((v, i) => video.playbackmethod.indexOf(i) >= 0),
					},
				};
			}
			return existingBidderParams;
		},
		appnexusAst: (existingBidderParamsArgs, { isVideo, getLatestVideoSpec }) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (isVideo) {
				const video = getLatestVideoSpec();
				existingBidderParams = {
					...existingBidderParams,
					video: {
						mimes: video.mimes,
						minduration: video.minduration,
						maxduration: video.maxduration,
						startdelay: video.startdelay,
						skippable: video.skip === 1,
						playback_method: ['', 'auto_play_sound_on', 'auto_play_sound_off', 'click_to_play'].filter((v, i) => video.playbackmethod.indexOf(i) >= 0),
					},
				};
			}
			return existingBidderParams;
		},
		criteo: (existingBidderParams, unitConfig) => ({ networkId: '2714', pubid: '5331_E4CMGJ', ...existingBidderParams }),
		grid: (existingBidderParams, unitConfig, context) => {
			const { extraParams } = context.bidderContext;
			return {
				...existingBidderParams,
				...extraParams,
			};
		},
		ix: (existingBidderParamsArgs, unitConfig) => {
			const existingBidderParams = existingBidderParamsArgs;
			if (unitConfig.isVideo || unitConfig.allowedTypes.video) {
				existingBidderParams.video = unitConfig.getLatestVideoSpec();
				return existingBidderParams;
			}
			if (!existingBidderParams.size) return null;
			const includesSize =
				unitConfig
					.getSizes()
					.map((s) => (Array.isArray(s) ? s.join('x') : s))
					.indexOf(existingBidderParams.size.join('x')) >= 0;
			return includesSize ? existingBidderParams : null;
		},
    kueezrtb: (existingBidderParams, unitConfig) => {
      if (!existingBidderParams.included) return null;
      // only fire this vendor if traffic is from the supported GEOS: United States, Canada, Mexico, United Kingdom,
      // Brazil, Germany, France, Spain, Argentina, Australia, Italy, Japan, Chile, Philippines, Colombia, Turkey,
      // South Africa, Netherlands, Peru, New Zealand, Switzerland, Hong Kong, Austria, Sweden, Singapore, Hungary,
      // United Arab Emirates, Puerto Rico, Saudi Arabia, Portugal, Ireland, Denmark, Costa Rica, Israel, Czech Republic,
      // Finland, Bulgaria, Honduras, Moldova, Latvia,
      if(! (['us', 'ca', 'mx', 'gb', 'br', 'de', 'fr', 'es', 'ar', 'au', 'it', 'jp', 'cl', 'ph', 'co', 'tr', 'za', 'nl',
         'pe', 'nz', 'ch', 'hk', 'at', 'se', 'sg', 'hu', 'ae', 'pr', 'sa', 'pt', 'ie', 'dk', 'cr', 'il', 'cz', 'fi',
         'bg', 'hn', 'md', 'lv'].includes($$BB_VAR$$?.getCountryCode()))) return null;
      return { cId: '678d1831fae6e33350c9365a', pId: '65lk7c192882r0011813fn9', ...existingBidderParams };
    },
		medianet: (existingBidderParams, unitConfig) => ({ cid: '8CU391FYK', ...existingBidderParams }),
		// eslint-disable-next-line no-unused-vars
		nobid: (existingBidderParams, unitConfig) => {
			if (!existingBidderParams.included) return null;
			return { siteId: '68361', ...existingBidderParams };
		},
		pubmatic: (existingBidderParamsArgs, unitConfig) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (!existingBidderParams.included) return null;
			if (unitConfig.isVideo || unitConfig.allowedTypes.video) {
				existingBidderParams = {
					...existingBidderParams,
					publisherId: '156082',
					adSlot: [
						// eslint-disable-next-line no-undef
						window.BidBarrel.dfpPathObj.property,
						unitConfig.originalCode || unitConfig.code,
					].join('_'),
					video: {
						plcmt: 1,
						placement: 1,
					},
				};
				return existingBidderParams;
			}
			const sizes = unitConfig.getSizes();
			const compatibleSizes = ['300x250', '320x50', '728x90', '970x250', '300x600'];
			const isCompatibleSize = (size) => (typeof size === 'string' ? false : compatibleSizes.indexOf(size.join('x')) >= 0);
			const processedSizes = sizes.filter(isCompatibleSize);
			return processedSizes.length <= 0
				? null
				: processedSizes.map((size) => ({
						publisherId: '156082',
						adSlot: [
							// eslint-disable-next-line no-undef
							window.BidBarrel.dfpPathObj.property,
							unitConfig.originalCode || unitConfig.code,
							size.join('x'),
						].join('_'),
					}));
		},
		rise: (existingBidderParams, unitConfig) => {
			if (!existingBidderParams.included) return null;
			return { org: '642c4ef8ef16c40001db86d9', ...existingBidderParams };
		},
		spotx: (existingBidderParamsArgs, { isVideo, getSizes }) => {
			let existingBidderParams = existingBidderParamsArgs;
			if (isVideo) {
				//   const sizes = getSizes();
				//   const widths = sizes.map(v => v[0]);
				//   const heights = sizes.map(v => v[1]);
				//   const max_height = Math.max(...heights);
				//   const max_width = Math.max(...widths);
				//   const min_height = Math.min(...heights);
				//   const min_width = Math.min(...widths);
				existingBidderParams = {
					...existingBidderParams,
					price_floor: 1,
					//   ext: {
					//     max_width,
					//     max_height,
					//     min_height,
					//     min_width
					//   }
				};
			}
			return existingBidderParams;
		},
		// eslint-disable-next-line no-unused-vars
		ttd: (existingBidderParamsArgs, unitConfig) => {
			const existingBidderParams = existingBidderParamsArgs;
			if (!existingBidderParams.included) return null;
			return { supplySourceId: 'ziffdavis', publisherId: '1', ...existingBidderParams };
		},
		teads: (value) => {
			if (!value.placementId || !value.pageId) return null;
			return {
				pageId: value.pageId ? parseInt(value.pageId, 10) : '',
				placementId: value.placementId ? parseInt(value.placementId, 10) : '',
			};
		},
		trustx: (existingBidderParams, unitConfig, context) => {
			const { extraParams } = context.bidderContext;
			return {
				...existingBidderParams,
				...extraParams,
			};
		},
		yahooAds: (existingBidderParams, unitConfig) => ({ network: '58918', ...existingBidderParams }),
		yieldmo: (existingBidderParams, unitConfig, context) => ({ placementId: `${existingBidderParams.placementId}` }),
		/* eslint-enable no-unused-vars */

		// sonobi: (existingBidderParams, unitConfig, context) => {
		// 	if (!existingBidderParams.included || unitConfig.isVideo || unitConfig.allowedTypes.video) return null;
		// 	const { network, device, region, property } = context.dfpPath;
		// 	return {
		// 		dom_id: unitConfig.code,
		// 		// eslint-disable-next-line prefer-template
		// 		ad_unit: `/${network}/${device}${region}-${property}/${unitConfig.originalCode}` + (unitConfig.incremental ? unitConfig.index : ''),
		// 	};
		// },
	},
	enrichment: {
    monarchEnabled: true,
		token: '6e4d8710-04aa-4aba-8ea0-6436ce2e14c6',
		endpoint: {
			// ...ruleset/{sourceId}/{rulesetId}
			prod: 'https://monarch.cohesionapps.com/api/v1/evaluate/ruleset/6c8c3ead-bc7a-4fe6-98e6-532258665aee/57ab7954-a37c-4779-9e02-b364f56ce84c',
			dev: 'https://monarch.cohesionapps.com/api/v1/evaluate/ruleset/6c8c3ead-bc7a-4fe6-98e6-532258665aee/c2fcd245-46d1-4784-843e-7ffdeca5a0d9',
		},
		enrichPrebid: true,
	},
	errorReporting: {
		chunkSize: 20,
		reportInterval: 15000,
		reportingPercentage: 1,
	},
	geo: {
		enabled: true,
		failsafeRegionCode: 'us',
		failsafeCountryCode: 'us',
		failsafeTimeout: 2000,
		// eslint-disable-next-line global-require
		regionDefinitions: require('./region-definitions.json'),
	},
	highFrequencyAdRequests: false,
	ias: {
		publisherOptimization: {
			scriptUrl: 'https://cdn.adsafeprotected.com/iasPET.1.js',
		},
	},
	// eslint-disable-next-line global-require
	identity: require('./identity.json'),
	lazyLoading: {
		auction: {
			enabled: false,
			chunkSize: 15,
			chunk: true,
			lazy: false,
			offset: {
				yAxis: 1000,
				xAxis: 1600,
			},
			percentageVisible: 1,
		},
		units: {
			offset: {
				yAxis: 400,
				xAxis: 800,
			},
			percentageVisible: 1,
		},
	},
	dv: {
		enabled: true,
		scriptUrl: 'https://pub.doubleverify.com/dvtag/33509408/DV1307377/pub.js',
	},
  intentIQ: {
		enabled: false,
		scriptUrl: 'https://www.cnet.com/irb/lib/dist/main/IIQUniversalID.js',
		params: {
			partner: 431700236,
			timeoutInMillis: 400,
			ABTestingConfigurationSource: 'percentage',
			abPercentage: 95,
			manualWinReportEnabled: true,
			vrBrowserBlackList: ['chrome'],
		},
	},
	prebid: {
		enabledRegions: ['us', 'emea', 'apac'],
		cacheUrlConfigs: {
			prefix: 'bb_vcache_',
			bidders: {
				default: {
					// url: "prebid.adnxs.com/pbc/v1/cache",
					// key: "uuid"
				},
			},
			// default: "https://dev.at.cbsi.com/lib/api/v1/vcache?uuid="
			// spotx: "https://search.spotxchange.com/ad/vast.html?key="
		},
		ids: {
			pubCommon: {
				enabled: true,
				autoDeleteStorage: false,
				userSyncConfig: {
					name: 'pubCommonId',
					storage: {
						type: 'cookie',
						name: '_pubcid',
						expires: 365,
					},
				},
			},
			identityLink: {
				enabled: false,
				template: true,
				trackChanges: true,
				autoDeleteStorage: false,
				shouldRefresh: (oldConfig, newConfig) => {
					if (!newConfig || !newConfig.params) return false;
					if ((!oldConfig && newConfig) || (!oldConfig.params && newConfig.params)) return true;
					return (oldConfig.params.eid === '' && newConfig.params.eid !== '') || (newConfig.params.eid !== '' && newConfig.params.eid !== oldConfig.params.eid);
				},
				dependencies: "<%= context.getValue('config.dfpPath') %>",
				onRemove: {
					cookies: ['_lr_retry_request'],
				},
				userSyncConfig: {
					name: 'identityLink',
					params: {
						eid: "<%= context.getValue('config.firstPartyData.eid') %>",
					},
					storage: {
						type: 'cookie',
						name: '_BB.id.identityLink',
						expires: 15,
					},
				},
			},
			liveIntent: {
				enabled: liModuleEnabled,
				trackChanges: true,
				autoDeleteStorage: false,
				userSyncConfig: {
					name: 'liveIntentId',
					params: {
						liCollectConfig: {
							appId: 'a-0168',
						},
            // eslint-disable-next-line no-underscore-dangle, no-undef
            // emailHash: (dom().window.__ZDU && dom().window.__ZDU.md5) ? dom().window.__ZDU.md5 : null,
						requestedAttributesOverrides: {
							uid2: true,
							bidswitch: true,
							medianet: true,
							magnite: true,
							pubmatic: true,
							index: true,
              openx: true,
              thetradedesk: true,
              sovrn: true,
						},
						partner: 'prebid',
					},
					storage: {
            type: 'html5',
            name: '__tamLIResolveResult',
            expires: 1
					},
				},
			},
			criteo: {
				enabled: false,
				autoDeleteStorage: false,
				userSyncConfig: {
					name: 'criteo',
				},
			},
			unifiedId: {
				enabled: false,
				userSyncConfig: {
					name: 'unifiedId',
					params: {
						partner: 'osl8vzb',
					},
				},
			},
		},
		allowedSizes: [
			[5, 5],
			[6, 6],
			[300, 250],
			[320, 50],
			[728, 90],
			[640, 480],
			[970, 250],
			[300, 600],
			[160, 600],
			[300, 251],
			[11, 11],
		],
		priceBuckets: standardPriceBuckets,
		pbjsConfig: {
			enableTIDs: true,
			allowActivities: {
				transmitTid: {
					rules: [{ allow: true }],
				},
			},
			transmitTid: true,
			bidderSettings: {
				standard: {
					storageAllowed: true,
				},
				criteo: {
					storageAllowed: true,
				},
			},
			gptPreAuction: {
				enabled: true,
				useDefaultPreAuction: false,
				customPreAuction(adUnitCode, adServerAdSlotArg) {
					let adServerAdSlot = adServerAdSlotArg;
					if (typeof adServerAdSlotArg === 'undefined') {
						// eslint-disable-next-line no-undef
						adServerAdSlot = window.BidBarrel.dfpPathObj.string;
					}
					return `${adServerAdSlot}#${adUnitCode.code}`;
				},
			},
			userSync: {
				syncEnabled: true,
				iframeEnabled: true,
				auctionDelay: liModuleEnabled ? 300 : 100,
				syncsPerBidder: 8,
				syncDelay: 3000,
				filterSettings: {
					iframe: {
						bidders: '*',
						filter: 'include',
					},
					image: {
						bidders: '*',
						filter: 'include',
					},
				},
			},
			priceGranularity: {
				buckets: standardPriceBuckets,
			},
			mediaTypePriceGranularity: {
				video: { buckets: videoPriceBuckets },
			},
			enableSendAllBids: false,
			floors: {
				enabled: false,
				data: {
					currency: 'USD',
					skipRate: 0,
				},
				additionalSchemaFields: {
					fold: (bidRequest) => {
						// video units have a -- appended to the adUnitCode
						const adUnitCodeMatch = bidRequest?.adUnitCode?.match(/(.*)--/);
						const adUnitCode = adUnitCodeMatch ? adUnitCodeMatch[1] : bidRequest?.adUnitCode;
						const pos = $$BB_VAR$$?.adUnitsObj?.[adUnitCode]?.targeting?.pos ?? '';
						if (pos === 'sticky' || pos === 'top' || pos === 'nav') {
							return 'atf';
						}
						return 'btf';
					},
					topAdUnit: (bidRequest) => {
						let topAdUnit = '';
						const adSlot = bidRequest?.ortb2Imp?.ext?.data?.adserver?.adslot;
						if (adSlot !== undefined) {
							// eslint-disable-next-line no-useless-escape
							topAdUnit = /(\/[0-9]*\/[^\/]*)/g.exec(adSlot)?.[0] ?? undefined;
						}
						return topAdUnit;
					},
				},
			},
			s2sConfig: {
				accountId: '12849',
				enabled: true,
				defaultVendor: 'appnexuspsp',
				allowUnknownBidderCodes: true,
				extPrebid: { targeting: { includebidderkeys: true, includewinners: true } },
				bidders: ['appnexus'],
				timeout: 1000,
				adapter: 'prebidServer',
				endpoint: {
					p1Consent: 'https://ib.adnxs.com/openrtb2/prebid',
					noP1Consent: 'https://ib.adnxs-simple.com/openrtb2/prebid',
				},
			},
			targetingControls: {
				alwaysIncludeDeals: true,
			},
			cache: {
				url: 'https://prebid.adnxs.com/pbc/v1/cache',
			},
		},
	},
	priorityAxis: 'y',
	consentConfig: {
		type: 'zdconsent',
		timeout: 2000,
	},
	targeting: {
		auto: false,
		query: {
			keyMap: {},
		},
		seats: {
			session: 6,
			subsession: 4,
		},
		cookie: {
			expiration: null,
			path: '/',
			domain: null,
			samesite: 'None',
			consolidate: false,
			keyMap: {
				ftag: 'ftag',
				ttag: 'ttag',
				firstpg: 'first_page_today',
			},
			getters: {},
			setters: {},
			deleters: {},
		},
		track3pc: {
			enabled: true,
			url: 'https://irb.cnetstatic.com/lib/dist/main/cookietest.html',
		},
	},
	timeouts: {
		failsafe: 3000,
		bidder: 1000,
		hfar: 700,
	},
	video: {
		detectCapabilities: true,
		spec: {
			// refer to https://www.iab.com/wp-content/uploads/2016/03/OpenRTB-API-Specification-Version-2-5-FINAL.pdf under video section for keys / values
			mimes: ['video/mp4', 'video/H264', 'video/webm'],
			startdelay: 0,
			w: 640,
			h: 480,
			skip: 0,
			minduration: 5,
			maxduration: 30,
			context: 'instream',
			api: [1, 2],
			linearity: 1,
			protocols: [1, 2, 3, 4, 5, 6, 7, 8],
			playbackmethod: [2],
			playbackend: 1,
			minbitrate: 360,
			maxbitrate: 2500,
			plcmt: 2,
			placement: 4,
			vpmute: 2,
		},
		getSpec: (existingSpec, { PLAYBACK_METHOD }, context) => {
			const modifiedValue = existingSpec;
			// Exclude vpaid on mobile
			if (context.isMobile()) {
				modifiedValue.api = modifiedValue.api.filter((v) => [1, 2].indexOf(v) === -1);
			}
			// When VPAID enabled exclude video/h264 or video/webm mimes
			if (modifiedValue.api.indexOf(1) >= 0 || modifiedValue.api.indexOf(2) >= 0) {
				modifiedValue.mimes = modifiedValue.mimes.filter((v) => ['video/H264', 'video/webm'].indexOf(v) === -1);
			}

			if (context.canAutoplayUnmuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_ON, PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else if (context.canAutoplayMuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.CLICK_SOUND_ON];
			}

			return modifiedValue;
		},
		// eslint-disable-next-line no-unused-vars
		getUnitSpec: (existingSpec, unitConfig, { PLAYBACK_METHOD }) => ({ sequence: unitConfig.sequenceIndex, ...existingSpec }),
		translateSpec: (existingSpec, unitConfig, { PLAYBACK_METHOD }, context) => {
			const modifiedValue = existingSpec;
			let requestedFunctionality;
			if (modifiedValue.h === '480v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_ON;
			} else if (modifiedValue.h === '483v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_OFF;
			} else if (modifiedValue.playerSize && modifiedValue.playerSize.length === 2 && modifiedValue.playerSize[1] === '480v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_ON;
			} else if (modifiedValue.playerSize && modifiedValue.playerSize.length === 2 && modifiedValue.playerSize[1] === '483v') {
				requestedFunctionality = PLAYBACK_METHOD.PAGELOAD_SOUND_OFF;
			}
			if (requestedFunctionality === PLAYBACK_METHOD.PAGELOAD_SOUND_OFF && context.canAutoplayMuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else if (requestedFunctionality === PLAYBACK_METHOD.PAGELOAD_SOUND_ON && context.canAutoplayUnmuted) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.PAGELOAD_SOUND_ON, PLAYBACK_METHOD.PAGELOAD_SOUND_OFF];
			} else if (requestedFunctionality) {
				modifiedValue.playbackmethod = [PLAYBACK_METHOD.CLICK_SOUND_ON];
			}

			if (requestedFunctionality) {
				modifiedValue.h = 480;
			}
			if ((!modifiedValue.h || !modifiedValue.w) && modifiedValue.playerSize && modifiedValue.playerSize.length === 2) {
				if (!modifiedValue.w) {
					// eslint-disable-next-line prefer-destructuring
					modifiedValue.w = modifiedValue.playerSize[0];
				}
				if (!modifiedValue.h) {
					// eslint-disable-next-line prefer-destructuring
					modifiedValue.h = modifiedValue.playerSize[1];
				}
			} else if (!existingSpec.playerSize || modifiedValue.playerSize.length !== 2) {
				modifiedValue.playerSize = [modifiedValue.w, modifiedValue.h];
			}

			return modifiedValue;
		},
		defaultParamOptions: {
			// eslint-disable-next-line no-unused-vars
			getParams: (unitConfig, index) => ({}),
			params: {
        plcmt: 2,
      },
			targeting: {
				unit: false,
				page: false,
				allowlist: [
          'session', 'subses', 'subsession', 'pv', 'vguid', 'abtest', 'ftag', 'useg',
          'cid', 'collection', 'tag', 'topic', 'ptopic', 'ptype', 'ctopic', 'entity', 'subcollection',
          'bsc', 'ids', 'tvp', 'vlp', // DV related
          'vid', 'mfr', 'contentType', 'device'
        ],
			},
		},
		getDefaultParamOptions: (currentOptions) => currentOptions,
		adServerUrl: 'https://pubads.g.doubleclick.net/gampad/ads',
	},
};
