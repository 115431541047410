import CONSTANTS from '../../constants.json';
import { moduleManager } from '../../moduleManager.js';
import { renderScript } from '../../utilities/renderScript.js';

const { KUEEZ_HELPER } = CONSTANTS.MODULES;

/**
 * Kueez Module
 *
 * Module used to import script needed by Kueez for header bidding
 *
 * @module KueezModule
 */
const kueezModuleBase = (function modBase() {
	/**
	 * Register module and load Kueez script
	 *
	 * @private
	 * @memberof KueezModule
	 */
	function register() {
		// <script type="text/javascript" data-cfasync="false" crossorigin="anonymous" src="https://static.kueezrtb.com/latest.js" id="kueezrtb_latest"></script>
		renderScript({
			src: 'https://static.kueezrtb.com/latest.js',
			id: 'kueezrtb_latest',
			crossorigin: 'anonymous',
			'data-cfasync': 'false',
		});
	}

	return {
		name: KUEEZ_HELPER,
		register,
	};
})();

export const kueezModule = moduleManager.register(kueezModuleBase, null, { gate: 'consentGiven' });
export default kueezModule;
