import isEqual from 'lodash/isEqual.js';
import { getConfig } from '../../config.js';
import CONSTANTS from '../../constants.json';
import context from '../../context.js';
import { eventEmitter } from '../../events.js';
import { exposureApi } from '../../exposureApi.js';
import { features } from '../../features.js';
import { moduleManager } from '../../moduleManager.js';
import { api } from '../../services/api.js';
import { errorReporting } from '../../services/errorReporting.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import { isStagingEnv } from '../../utilities/environment.js';
import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';
import errorReplacer from '../../utilities/helpers/errorReplacer.js';
import keyBy from '../../utilities/helpers/keyBy.js';
import { logger } from '../../utilities/logger.js';
import { percentageRunner, percentageShouldRun } from '../../utilities/percentageRunner.js';
import { getPerformanceConsentGiven } from '../third-party/consentWorker.js';

const bbaLogger = logger({ name: 'analytics', bgColor: '#8F8389' });

const RECORD_VERSION = '2';

const {
	EVENTS: { ANALYTICS_RECORD_CREATED, ANALYTICS_REPORTED, ANALYTICS_CANCELLED },
	MODULES: { BIDBARREL_ANALYTICS },
} = CONSTANTS;
/**
 * BidBarrel Analytics Module for recording all records around prebid bids
 *
 * @module BidBarrelAnalytics
 * @private
 */
const analyticsModuleBase = ( () => {
	/**
	 * Flag for tracking if reporting has been setup yet
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	let reportingSetup = false;
	/**
	 * A registry of records where keys match up to configured ids and contains a queued array of data
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	const records = {};
	/**
	 * All collected records of data from this module
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	const allRecords = [];
	/**
	 * Tracks queued records
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	const queuedRecords = [];
	/**
	 * A registry of intervals for each reporting configuration with IDs as keys and intervals as values
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	const reportingIntervals = {};
	/**
	 *  A registry of configurations where the configurations have been keyed by id
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	let config = {};
	/**
	 *  A registry of failed attempts keyed by id
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	const failedReports = {};
	/**
	 * An array tracking contexts for each record
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	const contexts = [];
	/**
	 * Binds records with context indices to their respective contexts
	 *
	 * @param {BidBarrel~AnalyticsRecord[]} records
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function bindContexts(contextRecords = []) {
		const results = [];
		for (let index = 0; index < contextRecords.length; index += 1) {
			const { contextIndex, ...record } = contextRecords[index];
			if (typeof contextIndex !== 'undefined') {
				results.push({ ...record, ...contexts[contextIndex] });
			}
		}
		return results;
	}
	/**
	 * Reports a failing report event such that reporting can be cancelled after given threshold
	 *
	 * @param {Error} err
	 * @param {BidBarrel~AnalyticsConfig} cfg
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function reportFail(err, cfg) {
		if (err) {
			bbaLogger.logError(err);
			const errorObj = new Error(`Analytics Report Failed. ${JSON.stringify(err, errorReplacer)}`);
			errorReporting.report(errorObj);
		}
		if (!failedReports[cfg.id]) {
			failedReports[cfg.id] = 0;
		}
		failedReports[cfg.id] += 1;
		if (typeof cfg.failThreshold !== 'undefined' && failedReports[cfg.id] >= cfg.failThreshold) {
			bbaLogger.logInfo('Fail threshold reached for', cfg.id, 'cancelling..');
			clearInterval(reportingIntervals[cfg.id]);
		}
	}

	function getRelatedContextsForBeacon(relatedRecords = []) {
		const result = {};
		for (let index = 0; index < relatedRecords.length; index += 1) {
			const record = relatedRecords[index];
			const ctxKey = `ctx${record.contextIndex}`;
			if (!result[ctxKey]) {
				result[ctxKey] = contexts[record.contextIndex];
			}
		}
		return result;
	}
	/**
	 * Applies default fetch options to a fetch http request
	 *
	 * See {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch|MDN Guide on Using Fetch}
	 *
	 * @param {Object|void} existingOptions
	 * @param {BidBarrel~AnalyticsRecord} data
	 * @returns {Object} final fetch options
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function withDefaultFetchOptions(existingOptions, data) {
		return defaultsDeep({}, existingOptions || {}, {
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify(data),
		});
	}
	/**
	 * Handles logic around reporting records via the preferred configured method
	 *
	 * @param {BidBarrel~AnalyticsConfig} cfg
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function reportRecords(cfg) {
		const { id, report, url, fetchOptions, transport } = cfg;
    if(!getPerformanceConsentGiven()) {
      bbaLogger.logInfo('Performance consent not given, skipping analytics report');
    } else if (records[id] && records[id].length > 0) {
			if (report) {
				try {
					const publicRecords = bindContexts(records[id]);
					bbaLogger.logInfo('(Method) Reporting Events for config', id, 'events:', publicRecords[id]);
					report(publicRecords[id]);
					records[id] = [];
				} catch (err) {
					reportFail(err, cfg);
				}
			} else if (url) {
				let fallbackPost = false;
				if (transport === 'beacon') {
					const relatedContexts = getRelatedContextsForBeacon(records[id]);
					bbaLogger.logInfo('(Beacon) Reporting Events for config', id, 'events:', records[id], relatedContexts);
					const wasQueued = api.sendBeacon(url, { records: records[id], contexts: relatedContexts });
					if (wasQueued) {
						records[id] = [];
					} else {
						fallbackPost = true;
					}
				}
				if (transport !== 'beacon' || fallbackPost) {
					const publicRecords = bindContexts(records[id]);
					bbaLogger.logInfo('(Post) Reporting Events for config', id, 'events:', publicRecords);
					api
						.post(url, withDefaultFetchOptions(fetchOptions, publicRecords))
						.then(({ _response }) => {
							if (_response.status >= 200 && _response.status < 300) {
								records[id] = [];
							} else {
								reportFail(null, cfg);
							}
						})
						.catch((err) => reportFail(err, cfg));
				}
			} else {
				bbaLogger.logError("Must provide a 'report' or 'url' property for BidBarrel Analytics");
				const errorObj = new Error(`Must provide a 'report' or 'url' property for BidBarrel Analytics.`);
				errorReporting.report(errorObj);
			}
		}
	}
	/**
	 * Function getter for the significant context values for analytics records
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function getAnalyticsContext() {
		const definedStrOr = (value, orVal = null) => (typeof value === 'undefined' || value === null ? orVal : `${value}`);
		const analyticsContext = {
			version: RECORD_VERSION,
			hostname: context.getValue('hostname') || null,
			page: context.getValue('page') || null,
			abStr: context.getValue('abStr') || null,
			gaClientId: context.getValue('cookie.gaClientId') || null,
			aamUuid: context.getValue('cookie.aamUuid') || null,
			env: context.getValue('targeting.env') || context.getValue('config.pageTargeting.env') || (isStagingEnv() ? 'stage' : 'prod') || null,
			dfpPath: context.getValue('config.dfpPathObj.string') || null,
			pv: definedStrOr(context.getValue('targeting.pv'), null),
			ftag: context.getValue('targeting.ftag') || null,
			ttag: context.getValue('targeting.ttag') || null,
			session: context.getValue('targeting.session') || null,
			subses: context.getValue('targeting.subses') || null,
			ptype: context.getValue('targeting.ptype') || null,
			vguid: context.getValue('targeting.vguid') || null,
			bidbarrelVersion: context.getValue('bidbarrelVersion') || null,
			connectionType: context.getValue('client.connectionType') || null,
			connectionSpeed: context.getValue('client.connectionSpeed') || null,
			regionCode: context.getValue('client.region') || null,
			countryCode: context.getValue('client.country') || null,
			subCountryCode: context.getValue('client.subregion') || null,
			vpWidth: context.getValue('client.viewportWidth') || null,
			vpHeight: context.getValue('client.viewportHeight') || null,
			configVersion: context.getValue('config._remoteContext.version') || null,
			authenticated: context.getValue('authenticated') || false,
		};

		let contextIndex;

		for (let index = 0; index < contexts.length; index += 1) {
			const contextObj = contexts[index];
			if (isEqual(analyticsContext, contextObj)) {
				contextIndex = index;
			}
		}

		if (typeof contextIndex === 'undefined') {
			contexts.push(analyticsContext);
			contextIndex = contexts.length - 1;
		}

		return [contextIndex, contexts[contextIndex]];
	}
	/**
	 * Adds a record to all configured queues
	 *
	 * @param {BidBarrel~AnalyticsRecord} record
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function addRecord(record) {
		if (!reportingSetup) {
			queuedRecords.push(record);
			return;
		}
		const [contextIndex, currentContext] = getAnalyticsContext();
		const publicRecord = { ...record, ...currentContext };
		const privateRecord = { ...record, contextIndex };
		allRecords.push(privateRecord);
		Object.keys(config).forEach((id) => {
			if (Object.prototype.hasOwnProperty.call(config, id)) {
				if (!records[id]) {
					records[id] = [];
				}
				records[id].push(privateRecord);
			}
		});
		eventEmitter.emit(ANALYTICS_RECORD_CREATED, publicRecord);
	}
	/**
	 * Handles processing queued records
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function addQueuedRecords() {
		if (typeof queuedRecords !== 'undefined') {
			for (let index = 0; index < queuedRecords.length; index += 1) {
				const rec = queuedRecords[index];
				addRecord(rec);
			}
		}
	}
	/**
	 * Sets up reporting intervals for each configuration
	 *
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function setupReporting() {
		if (reportingSetup) return;
		Object.keys(config).forEach((id) => {
			if (Object.prototype.hasOwnProperty.call(config, id)) {
				const currentConfig = config[id];
				const sessionConfig = currentConfig.session;
				if (
					features.get([`forceRun.${BIDBARREL_ANALYTICS}`, 'forceRun.all']) ||
					typeof sessionConfig === 'undefined' ||
					percentageShouldRun(sessionConfig.shouldReport || sessionConfig.reportingPercentage, true)
				) {
					reportingIntervals[id] = setInterval(() => {
						percentageRunner(
							features.get([`forceRun.${BIDBARREL_ANALYTICS}`, 'forceRun.all']) || currentConfig.shouldReport || currentConfig.reportingPercentage,
							() => {
								eventEmitter.emit([`${id}.${ANALYTICS_REPORTED}`, ANALYTICS_REPORTED], bindContexts(records[currentConfig.id]), currentConfig);
								reportRecords(currentConfig);
							},
							true
						);
					}, currentConfig.frequency);
				}
			}
		});
		reportingSetup = true;
		addQueuedRecords();
	}

	/**
	 * Initialization method for Module
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function initialize() {
		setupReporting();
	}

	/**
	 * Sets the configurations for this module
	 *
	 * @memberof BidBarrelAnalytics
	 * @private
	 */
	function register() {
		getConfig('analytics.bidbarrel', (value) => {
			config = keyBy(value, 'id');
		});
	}

	/**
	 * Cancels reporting for a configuration
	 *
	 * Requires module: `bidBarrelAnalytics`
	 *
	 * @param {String} id matches id property of BidBarrel~AnalyticsConfig
	 * @memberof BidBarrelAnalytics
	 * @private
	 * @exposed
	 */
	function cancelReporting(id) {
		if (reportingIntervals[id] && !config[id].protected) {
			eventEmitter.emit([`${id}.${ANALYTICS_CANCELLED}`, ANALYTICS_CANCELLED], config[id]);
			clearInterval(reportingIntervals[id]);
		}
	}
	/**
	 * Gets all analytics records available since page load
	 *
	 * Requires module: `bidBarrelAnalytics`
	 *
	 * @param {Boolean} [bindContextIndices=true] whether or not to bind the context to each record
	 * @returns {BidBarrel~AnalyticsRecord[]}
	 * @memberof BidBarrelAnalytics
	 * @private
	 * @exposed
	 */
	function getAllAnalyticsRecords(bindContextIndices = true) {
		return cloneDeep(bindContextIndices ? bindContexts(allRecords) : allRecords);
	}
	/**
	 * Gets the analytics record registry which is an object where the configuration ids are keys and the values are the arrays of currently queued BidBarrel~AnalyticsRecords
	 *
	 * Requires module: `bidBarrelAnalytics`
	 *
	 * @param {Boolean} [bindContextIndices=true] whether or not to bind the context to each record
	 * @returns {Object}
	 * @memberof BidBarrelAnalytics
	 * @private
	 * @exposed
	 */
	function getAnalyticsRecords(bindContextIndices = true) {
		if (!bindContextIndices) return cloneDeep(records);
		const result = {};
		Object.keys(records).forEach((id) => {
			if (Object.prototype.hasOwnProperty.call(records, id)) {
				const recordSet = records[id];
				result[id] = bindContextIndices(recordSet);
			}
		});
		return result;
	}

	exposureApi.expose({
		getAllAnalyticsRecords,
		getAnalyticsRecords,
		cancelReporting,
	});

	return {
		initialize,
		register,
		addRecord,
		name: BIDBARREL_ANALYTICS,
	};
})();

// Registering module
export const analyticsModule = moduleManager.register(analyticsModuleBase);
export default analyticsModule;

// -----------------------------------
// ADDITIONAL DOCUMENTATION
// -----------------------------------
