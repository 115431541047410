/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import mergeWith from 'lodash/mergeWith';
import './services/clientInfo.js';
import { setConfigPublic, setConfig, getConfig } from './config.js';
import CONSTANTS from './constants.json';
import context from './context.js';
import { eventEmitter } from './events.js';
import { exposureApi } from './exposureApi.js';
import { dom } from './global.js';
import { requestManager } from './requestManager.js';
import GLOBAL_CONFIG from '../configs/global-config.js';
import { adIdentifier } from './services/adIdentifier.js';
import { api } from './services/api.js';
import { errorReporting } from './services/errorReporting.js';
import { storage } from './services/storage.js';
import { setBidTargeting, setInitialPageTargeting } from './targeting.js';
import { setupUnits, unitRegistry, getUnitCollection, getUnits } from './unitManager.js';
import { cloneDeep } from './utilities/cloneDeep.js';
import { elementSort } from './utilities/elementSort.js';
import { getEnvVal, isStagingEnv } from './utilities/environment.js';
import { chunk } from './utilities/helpers/chunk.js';
import defaultsDeep from './utilities/helpers/defaultsDeep.js';
import errorReplacer from './utilities/helpers/errorReplacer.js';
import get from './utilities/helpers/get.js';
import keyBy from './utilities/helpers/keyBy.js';
import { hookedFn } from './utilities/hookedFunction.js';
import { bbLogger, setDefaultConfig, turnOnDebug, getLogStack, debugOn } from './utilities/logger.js';
import { mergeDeep } from './utilities/mergeDeep.js';
import { promiseQueue } from './utilities/queue.js';
import { renderScript } from './utilities/renderScript.js';

// event name constants
const { LOAD, INSTANTIATED, INITIALIZE, BIDS_REQUESTED, BIDS_RETURNED, REFRESH, AUCTION, BIDDING_DONE } = CONSTANTS.EVENTS;

const BUNDLE_SITE = `$bidbarrel.site$`;

let _queue;

let doneInitializing = false;

dom().window.FEATURES = { NATIVE: false };
dom().window.FEATURES = { VIDEO: true };

export function getQueue() {
	return _queue;
}

export function setQueue(callbackArr) {
	if (!_queue) {
		_queue = promiseQueue('BidBarrel Primary Queue', callbackArr);
	} else {
		_queue = promiseQueue('BidBarrel Primary Queue', [..._queue.remainingItems, ...callbackArr]);
	}
}
/**
 * General page bootstrapping
 *
 * @param {object} pageTargeting
 * @memberof BidBarrel
 * @method
 * @private
 */
export const bootstrap = hookedFn('sync', (pageTargeting) => {
	const bootstrapRenderScripts = getConfig('bootstrap.renderScripts');
	if (bootstrapRenderScripts && bootstrapRenderScripts.length) {
		for (let index = 0; index < bootstrapRenderScripts.length; index += 1) {
			const scriptConfig = bootstrapRenderScripts[index];
			renderScript(scriptConfig);
		}
	}
	// Apply page level targeting with query param override
	setInitialPageTargeting(pageTargeting);
});
/**
 * Initialization call for the page. Allows overriding existing config values for the site
 *
 * @param {BidBarrel~Configuration} pubConfig Pub side developer specified config
 * @memberof BidBarrel
 * @type {Function}
 * @method
 * @exposed
 * @example
 * BidBarrel.initialize({
 *    dfpPath: "/8264/aw-comicbook",
 *    pageTargeting: {
 *      ptype: "news_article",
 *      cid: 259196,
 *      env: "prod",
 *      session: "c",
 *      subses: 4,
 *      ftag: "ftag_example_value",
 *      ttag: "ttag_example_value"
 *    }
 * })
 */
export const initialize = hookedFn('sync', (pubConfig) => {
	if (pubConfig.queryTargeting) {
		pubConfig.targeting = defaultsDeep({}, pubConfig.targeting, { query: pubConfig.queryTargeting });
	}
	const newConfig = pubConfig;
	newConfig._initOverrides = cloneDeep(pubConfig);
	if (pubConfig.dfpPath && typeof pubConfig.dfpPath === 'string') {
		newConfig.dfpPath = pubConfig.dfpPath;
	}
	setConfigPublic(newConfig);
	const config = getConfig();
	bbLogger.atVerbosity(1).logMessage('Initializing BidBarrel. Config:', config);
	doneInitializing = true;
	bootstrap(config.pageTargeting || config.siteTargeting);
	eventEmitter.emit(INITIALIZE);

	bbLogger.atVerbosity(4).logInfo('BidBarrel Initialized');
});
/**
 * Function to check whether the AdLibrary is done initializing.  Returns TRUE if initializtion is done and FALSE if it is not done.
 *
 * @memberof BidBarrel
 * @type {Function}
 *  @return bool true if initialization is done
 */

export const adLibInitialized = () => doneInitializing;
export const getAds = hookedFn('sync', (units) => units);
/**
 * Handles lazy loading of ad units.
 * For the most part it postpones the refresh until the div is in the viewport
 *
 * @param {BidBarrel~AdUnit[]} units
 * @private
 * @type {Function}
 * @method
 * @memberof BidBarrel
 */
export const lazyLoad = hookedFn('sync', (units) => {
	if (units && units.length) {
		try {
      const oldconfig = getConfig('lazyLoading');
      if(typeof oldconfig.percentageVisible !== 'undefined') {
        bbLogger.logWarn('`lazyLoading.percentageVisible` has been deprecated in favor of `lazyLoading.units.percentageVisible`')
      }
      if(typeof oldconfig.offset !== 'undefined') {
        bbLogger.logWarn('`lazyLoading.offset` has been deprecated in favor of `lazyLoading.units.offset`')
      }
			const config = getConfig('lazyLoading.units') || oldconfig;
			const observerConfig = {
				rootMargin: `${config.offset.yAxis}px ${config.offset.xAxis}px`,
				threshold: config.percentageVisible * 0.01,
			};
			bbLogger.logInfo('Setting up lazy loading for units', units);
			const lazyAds = units.map((unit) => dom().window.document.getElementById(unit.code));
			// eslint-disable-next-line no-undef
			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio > 0) {
						observer.unobserve(entry.target);
						setBidTargeting([unitRegistry[entry.target.id]]);
						eventEmitter.emit(`${entry.target.id}.${REFRESH}`, unitRegistry[entry.target.id]);
						bbLogger.logInfo('Refreshing lazy loaded unit', unitRegistry[entry.target.id]);
						delete unitRegistry[entry.target.id].cancelLazyLoad;
						getAds([entry.target.id]);
					}
				});
			}, observerConfig);
			lazyAds.forEach((ad) => {
				if (ad) {
					observer.observe(ad);
				}
			});
			for (let index = 0; index < units.length; index += 1) {
				const unit = units[index];
				unitRegistry[unit.code].cancelLazyLoad = () => {
					observer.unobserve(dom().window.document.getElementById(unit.code));
					if (unitRegistry[unit.code].cancelLazyLoad) {
						delete unitRegistry[unit.code].cancelLazyLoad;
					}
				};
			}
		} catch (err) {
			bbLogger.logError('Error encountered while attempting to lazy load', err);
			const errorObj = new Error(`Error encountered while attempting to lazy load. ${JSON.stringify(err, errorReplacer)}`);
			errorReporting.report(errorObj);
			for (let index = 0; index < units.length; index += 1) {
				const unit = units[index];
				eventEmitter.emit(`${unit.code}.${REFRESH}`, unit);
			}
			setBidTargeting(units);
			getAds(units);
		}
	}
});
/**
 * Performs the googletag ad server request
 *
 * @param {String[]|Array[]|BidBarrel~AdUnit[]} units Unit values to
 * be processed by {@link #BidBarrel#getUnitCollection|BidBarrel.getUnitCollection}. Which gets
 * the specified unit designation's unit config.
 * @param {PromiseResolve} [resolve=() => false]
 * @param {boolean} origin - assists with canceling race condition with fail safe refresh
 * @private
 * @type {Function}
 * @method
 * @returns {void}
 * @memberof BidBarrel
 *
 */
export const adServerRequest = hookedFn('sync', (units, resolve = () => false) => {
	if (!units || units.constructor !== Array || units.length === 0) {
		bbLogger.atVerbosity(2).logWarn('Ad server request attempted with invalid units collection', units);
		resolve();
		return;
	}

	if (typeof units[0] === 'string' || units[0].constructor === Array) {
		bbLogger.logError('This function is meant for internal BidBarrel use only');
		const errorObj = new Error(`This function is meant for internal BidBarrel use only.`);
		errorReporting.report(errorObj);
		resolve();
		return;
	}

	const refreshUnits = units;

	// Queue refresh/lazy loading
	const nonLazyUnits = refreshUnits.filter((u) => !u.isLazyLoaded());
	const lazyUnits = refreshUnits.filter((u) => u.isLazyLoaded());
	setBidTargeting(nonLazyUnits);
	eventEmitter.emit(REFRESH, nonLazyUnits);
	for (let index = 0; index < nonLazyUnits.length; index += 1) {
		const unit = nonLazyUnits[index];
		eventEmitter.emit(`${unit.code}.${REFRESH}`, unit);
	}
	bbLogger.atVerbosity(1).logInfo('Refreshing units', nonLazyUnits);
	// Refresh only top units on load
	getAds(nonLazyUnits);
	lazyLoad(lazyUnits);
	requestManager.reset();
	resolve();
});
/**
 * The header bidding request used internally by BidBarrel
 *
 * Hookable function
 *
 * @param {BidBarrel~AdUnit[]} - ad unit collection
 * @memberof BidBarrel
 * @type {Function}
 * @method
 * @private
 */
export const headerBidderRequest = hookedFn('sync', (unitCollection) => unitCollection);
export const processAdRequest = hookedFn('sync', (unitCollection) => {
	// let hasBidSets = false;
	for (let index = 0; index < unitCollection.length; index += 1) {
		const unit = unitCollection[index];
		// hasBidSets = hasBidSets || (unit.bids && unit.bids.length > 0);
		if (unit.cancelLazyLoad) {
			bbLogger.logInfo('Cancelling Lazy Load for unit', unit);
			unit.cancelLazyLoad();
		}
	}
	getQueue().push((resolve) => {
		bbLogger.atVerbosity(1).logInfo('Running auction in queue. Collection:', unitCollection);
		eventEmitter.emit(AUCTION, unitCollection);
		// Remove short-circuit to allow non-bidding modules to also complete (DVQT, ENR)
		// if (!hasBidSets) {
		// 	bbLogger.atVerbosity(3).logInfo('Short-circuiting header bidding due to no bidders scenario');
		// 	adServerRequest(unitCollection, resolve);
		// } else {
		let cancelAuction = false;
		const config = getConfig();
		const failsafe = setTimeout(() => {
			cancelAuction = true;
			bbLogger.atVerbosity(1).logInfo('Hit failsafe condition');
      eventEmitter.emit(BIDDING_DONE, unitCollection);
			adServerRequest(unitCollection, resolve);
		}, config.timeouts.failsafe);
		requestManager.enqueue(() => {
			if (!cancelAuction) {
				clearTimeout(failsafe);
				eventEmitter.emit(BIDS_RETURNED, unitCollection);
        eventEmitter.emit(BIDDING_DONE, unitCollection);
				adServerRequest(unitCollection, resolve);
			}
		});
		try {
			bbLogger.logInfo('Making header bidder request with unitCollection:', unitCollection);
			headerBidderRequest(unitCollection);
		} catch (err) {
			bbLogger.logError(err);
			const errorObj = new Error(`Failed header bidder request. ${JSON.stringify(err, errorReplacer)}`);
			errorReporting.report(errorObj);
		}
		eventEmitter.emit(BIDS_REQUESTED, unitCollection);
		//	}
	});
});
/**
 * Handles auction chunking and lazy loading
 *
 * @param {BidBarrel~AdUnit[]} unitCollection
 * @returns {BidBarrel~AdUnit[]}
 * @type {Function}
 * @method
 * @memberof BidBarrel
 * @private
 */
export const chunkAuction = (unitCollection) => {
	const config = getConfig('lazyLoading.auction');
	if (!config.chunk) return unitCollection;
	const unitChunks = chunk(elementSort(unitCollection, getConfig('priorityAxis')), config.chunkSize);
	if (unitChunks.length > 1) {
		bbLogger.logInfo('Splitting auction call into multiple calls. Chunks:', unitChunks, 'Config:', config);
		const auctionLazyLoadRegistry = {};
		// Index starts at one because we will execute the first chunk on return
		for (let index = 1; index < unitChunks.length; index += 1) {
			const unitSet = unitChunks[index];
			if (config.lazy) {
				const lazyLoadEl = unitSet[0].element;
				const id = unitSet[0].code;
				auctionLazyLoadRegistry[id] = { units: unitSet, element: lazyLoadEl };
			} else {
				// Pushes execution to the end of the call stack such that the first chunk will auction first
				setTimeout(() => processAdRequest(unitSet), 0);
			}
		}
		if (config.lazy) {
			const observerConfig = {
				rootMargin: `${config.offset.yAxis}px ${config.offset.xAxis}px`,
				threshold: config.percentageVisible * 0.01,
			};
			// eslint-disable-next-line no-undef
			const observer = new IntersectionObserver((entries) => {
				for (let ix = 0; ix < entries.length; ix += 1) {
					const entry = entries[ix];
					if (entry.intersectionRatio > 0) {
						observer.unobserve(entry.target);
						const { id } = entry.target;
						processAdRequest(auctionLazyLoadRegistry[id].units);
					}
				}
			}, observerConfig);
			for (let index = 1; index < unitChunks.length; index += 1) {
				const unitSet = unitChunks[index];
				const lazyLoadEl = unitSet[0].element;
				observer.observe(lazyLoadEl);
			}
		}
	}
	return unitChunks[0];
};

/**
   * Auction method.
   *
   * @param {string[]|BidBarrel~AdUnit[]|Array[]} units Unit values to
   * be processed by {@link #BidBarrel#getUnitCollection|BidBarrel.getUnitCollection}. Which gets
   * the specified unit designation's unit config.
   * @memberof BidBarrel
   * @type {Function}
   * @method
   * @exposed
   * @example
   *
   BidBarrel.auction([ // You can swap refresh out with auction here. If in doubt simply use auction.
       "leader-top", // Standard pre-defined unit
       ["mpu-inc", 2, {pos:"inc"}], // Incremental ad unit
       ["leader-top", null, {pos:"top"}], // standard predefined unit with icnremental unit syntax
       ["leader-top", null, {pos:"top"}, {cache: true, collapseEmptyDiv:[true]}], // standard predefined unit with incremental unit syntax and unit modifiers(also work on inc units, review BidBarrel unit docs for more info)
       {
           code: "leader-top", //  Define units on the fly(Not recommended)
           cache: false,
           sizes: [
               [970, 90],
               [970, 66],
               [728, 90],
               [970, 250]
           ],
           targeting: {
               pos: "top"
           },
           bids: [
               {
                   bidder: "yahooAds",
                   params: {
                       network: "9928.1",
                       placement: "3687854"
                   }
               },
               {
                   bidder: "yahooAds",
                   params: {
                       network: "9928.1",
                       placement: "5002410"
                   }
               },
               {
                   bidder: "appnexus",
                   params: {
                       placementId: "5757416"
                   }
               }
           ]
       }
   ])
   */
export const auction = hookedFn('sync', (units) => {
	if (Array.isArray(units)) {
		units = Array.prototype.slice.call(units);
	}

	if (typeof units === 'undefined' || [Array, String, Object].indexOf(units.constructor) === -1 || (units.constructor === Array && units.length === 0)) {
		bbLogger.logError('Invalid units array passed to BidBarrel.auction', units);
		const errorObj = new Error(`Invalid units array passed to BidBarrel.auction.`);
		errorReporting.report(errorObj);
		return;
	}
	bbLogger.atVerbosity(1).logInfo('Invoking BidBarrel.auction', units);
	let unitCollection = getUnitCollection(units);

	if (unitCollection.length === 0) {
		bbLogger.logError('No valid unit designations provided');
		const errorObj = new Error(`No valid unit designations provided.`);
		errorReporting.report(errorObj);
		return;
	}
	if (getConfig('lazyLoading.auction.enabled')) {
		unitCollection = chunkAuction(unitCollection);
	}
	processAdRequest(unitCollection);
});

/**
 * Function to auction all units that have been displayed
 *
 * @memberof BidBarrel
 * @exposed
 */
export function auctionAll() {
	const unitCodes = [];
	const allUnits = getUnits();
	Object.keys(allUnits).forEach((code) => {
		if (Object.prototype.hasOwnProperty.call(allUnits, code)) {
			const unitConfig = allUnits[code];
			if (unitConfig.displayed) {
				unitCodes.push(code);
			}
		}
	});
	auction(unitCodes);
}
/**
 * Function to determine if the current client side user is using a mobile environment
 *
 * @memberof BidBarrel
 * @returns {boolean}
 */
export function isMobile() {
	const dfpPathObj = getConfig('dfpPathObj');
	return dfpPathObj.device === 'mobile' || dfpPathObj.device === 'm';
}

function loadBidBarrel() {
	if (typeof GLOBAL_CONFIG.debug === 'object') {
		setDefaultConfig(GLOBAL_CONFIG.debug);
		bbLogger.setLoggerConfig(GLOBAL_CONFIG.debug);
	} else if (GLOBAL_CONFIG.debug) {
		turnOnDebug();
	}
	bbLogger.atVerbosity(1).logInfo(`BidBarrel rv$bidbarrel.version$ ( $bidbarrel.variant$ ) Loaded`);
	eventEmitter.emit(LOAD, $$BB_VAR$$);
}

function exposeMethods() {
	exposureApi.rootScope({
		auction,
		auctionAll,
		initialize,
		isStagingEnv,
		isMobile,
		getLogStack,
		adLibInitialized,
		refresh: auction,
		process: auction,
	});
	exposureApi.deprecateMethods(
		['adServerRequest', 'bootstrap', 'create', 'enableGoogletagServices', 'headerBidderRequest', 'lazyLoad', 'postProcessUnit', 'setInitialPageTargeting', 'setBidTargeting', 'setGptTargeting'],
		'v3.0.0'
	);
	exposureApi.rootScopeGetters({
		version: () => 'rv$bidbarrel.version$',
		variant: () => '$bidbarrel.variant',
		queue: () => getQueue(),
		created: () => true,
		apiReady: () => true,
		bidderTimeout: () => (getConfig('highFrequencyAdRequests') ? getConfig('timeouts.hfar') : getConfig('timeouts.bidder')),
	});
}
/**
 * This function wraps BidBarrel and allows for per-site configuration
 *
 * @param {BidBarrel~Configuration} siteConfig
 * @param {BidBarrel~AdUnit[]} siteUnits
 * @method
 * @private
 */
export async function instantiateBidBarrel(siteConfig, siteUnits) {
	if (!get(dom().window, 'BidBarrel.created')) {
		// Perform on load functionality
		loadBidBarrel();
		if (debugOn('true')) {
			siteConfig._static = {
				global: cloneDeep(GLOBAL_CONFIG),
				site: cloneDeep(siteConfig),
			};
		}

		// Apply Remote Configuration
		const property = BUNDLE_SITE;
		let staticConfig = defaultsDeep(siteConfig, GLOBAL_CONFIG);
		let staticUnits = keyBy(siteUnits, 'code');
		const onFailRun = (env, remoteValues) => {
			bbLogger.logWarn(`Failed to Apply Remote Config ( ${property}/${env} )`, remoteValues || {});
		};
		try {
      // Manually setting api config since BB is still initializing and api.get needs the config to proceed
      await api.setConfig(mergeDeep(GLOBAL_CONFIG.api, siteConfig.api));
      const remoteValues = await api.get(`${property}/${getEnvVal()}/config/diff`, { timeout: 3000, query: { variant: '$bidbarrel.variant$' } });
      const { payload, success } = remoteValues;
			if (success) {
				bbLogger.logInfo(`Applying Remote Config ( ${property}/${getEnvVal()} )`, payload);
				const { units, ...remoteConfig } = payload;
				// eslint-disable-next-line consistent-return
				const overrideArrays = (firstObjVal, secondObjVal) => {
					if (Array.isArray(firstObjVal) && Array.isArray(secondObjVal)) {
						return secondObjVal;
					}
				};
				staticConfig = mergeWith(staticConfig, remoteConfig, overrideArrays);
				staticUnits = mergeWith(staticUnits, units, overrideArrays);
				if (debugOn('true')) {
					staticConfig._remote = payload;
				}
			} else {
				onFailRun(getEnvVal(), remoteValues);
			}
		} catch (err) {
      bbLogger.logError(err);
			const errorObj = new Error(`Failed to apply remote config. ${JSON.stringify(err, errorReplacer)}`);
			errorReporting.report(errorObj);
			onFailRun('undetected');
		}

		setConfig(staticConfig);
		storage.setup();
		context.setup();
		errorReporting.setup();
		adIdentifier.setupListeners();
		// eslint-disable-next-line prefer-rest-params
		bbLogger.atVerbosity(1).logInfo('Instantiating Bid Barrel', arguments, get(dom().window, 'BidBarrel.queue'), 'with config', getConfig());
		setQueue(get(dom().window, 'BidBarrel.queue') || []);
		exposeMethods();
		if (staticUnits) {
			setupUnits(staticUnits);
		}
		if (getConfig('oncreate.renderScripts')) {
			renderScript(getConfig('oncreate.renderScripts'));
		}

		// Set Global
		exposureApi.bindToWindow();
		// Instantiation event
		eventEmitter.emit(INSTANTIATED, dom().window.BidBarrel);
		// Start promise queue
		getQueue().run();
		bbLogger.atVerbosity(4).logInfo('BidBarrel Instantiated');
	} else {
		bbLogger.atVerbosity(1).logWarn('BidBarrel attempted to be instantiated twice. This is usually caused by BidBarrel being included more than once on the page.');
	}
}

// -----------------------------------
// ADDITIONAL DOCUMENTATION
// -----------------------------------

/**
 * A read-only object representation of all query parameters for the current url
 *
 *
 * @name BidBarrel.query
 * @readonly
 * @type {Object}
 */
/**
 * A read-only flag to indicate BidBarrel's api methods are ready
 *
 *
 * @name BidBarrel.apiReady
 * @readonly
 * @type {Boolean}
 */

/**
 * A script render config is the configuration used by BidBarrel's internal renderScript utility function.
 *
 * The render script utility has two safeguards in place to prevent rendering a script multiple times. The function will check to see if any existing elements have the same id or if any existing elements have the same src attribute(if provided). If so the script will not be rendered
 *
 * @typedef BidBarrel~RenderScriptConfig
 * @type Object
 *
 * @param {String} id The render script utility requires a unique id for the script.
 * @param {any} [attribute] almost everything passed in as a config will be translated to an attribute off of the script element.
 * @param {String} [content] The body of the script.
 * @param {HTMLElement} [parent] The parent element to append the script tag to
 */

/**
 * BidBarrel Analytics Module reporting configuration
 *
 * @typedef BidBarrel~AnalyticsConfig
 * @type Object
 *
 * @param {String} id The id for this configuration
 * @param {Boolean} protected If set to true prevents the ability to cancel reporting
 * @param {BidBarrel~SessionAnalyticsConfig} session configuration to determine if reporting of events should occur for the current session
 * @param {Number} failThreshold number of times in which the reporting method can fail before cancellation of reporting occurs
 * @param {Number} frequency The millesecond interval for each report event to occur
 * @param {Number} reportingPercentage A number 1-100 that will be used to determine if a reporting event should occur
 * @param {Function} shouldReport A callback alternative to reportingPercentage that will run each time a reporting event is about to occur that returns a boolean true or false value on whether the reporting event should occur. This option takes precedence over `reporting Percentage`
 * @param {String} url A url to perform an HTTP Post request to with the queued records for this configuration
 * @param {Function} report A callback that recieves all queued records for that configuration that allows for custom reporting methods. This config options takes precedence over the `url` property and will be used as an alternative to an HTTP Post request
 * @param {Object} fetchOptions an override object that will be used to override the default fetch options for the module. This will be ignored if a `report` property is designated
 */
/**
 * Session Analytics Reporter Config
 *
 * @typedef BidBarrel~SessionAnalyticsConfig
 * @type Object
 *
 * @param {Number} reportingPercentage A number 1-100 that will be used to determine if a reporting events should occur for current session
 * @param {Function} shouldReport A callback alternative to reportingPercentage that will run each each session that returns a boolean true or false value on whether the reporting events should occur. This option takes precedence over `reportingPercentage`

 */
/**
 * BidBarrel Analytics record.
 *
 * To see an overview of analytics record data please refer to the following spreadsheet: {@link https://docs.google.com/spreadsheets/d/1UAeGSc4mRClP7DeAutiPpPDRHdzRuquMPxNRZ1L2vOg/edit?usp=sharing|Ad Library Analytics: Records Overview}
 * @typedef BidBarrel~AnalyticsRecord
 * @type {Object}
 *
 */

/**
 * A configuration for a prebid-specific analytics provider.
 *
 * <b>NOTE:</b> The configuring of a provider implicitly adds that provider's adapter to your BidBarrel bundle. The enabled flag with a value of `false` will not prevent an adapter from being included in your site bundle but will prevent the adapter from being enabled client-side.
 *
 * {@link http://prebid.org/overview/analytics.html|Read More here}
 *
 * @typedef BidBarrel~PrebidAnalyticsProvider
 * @type {Object}
 *
 * @param {Boolean} enabled A flag to indicate if that analytics provider should recieve the prebid analytics events
 * @param {String} provider The code to identify the analytics provider for Google Analytics this is ga
 * @param {Object} options The options passed to the analytics adapter
 */
