
import { auction } from '../../bidbarrel.js';
import { getConfig } from '../../config.js';
import CONSTANTS from '../../constants.json';
import { eventEmitter } from '../../events.js';
import { dom } from '../../global.js';
import { moduleManager } from '../../moduleManager.js';
import { setTargeting } from '../../targeting.js';
import { logger } from '../../utilities/logger.js';

const { AD_RENDERED, AD_VIEWABLE, AD_VISIBILITY_CHANGED  } = CONSTANTS.EVENTS;
const { AD_REFRESH, GOOGLE_PUBLISHER_TAG } = CONSTANTS.MODULES;

const refreshLogger = logger({name: AD_REFRESH, bgColor: "#00aa00", textColor: "#fff8dc"});

/**
 * Ad Refresh
 *
 * This module allows ads which have been in view refresh to request a new ad from Google Ad Manager
 *
 * @module AdRefresh
 */
const adRefreshModuleBase = (function modBase() {
  /**
     * Configuration object
     *
     * @memberof AdRefresh
     * @private
     */
  let config = {};
  const slotStatus = {};
  const timesRefreshed = {};
  const lineItemHistory = [];

  let lastUserActivity = Date.now();;
  let refreshTimer;

  /**
   * Calculate the minimum time an ad slot must be in view before it is eligible for refresh.
   * @param {*} advertiserId The advertiserId of the ad slot
   * @returns {Array} inviewTime, inviewNote
   */
  function calcReqInviewTime(advertiserId) {
    let inviewTime = config.minTimeDefault;
    let inviewNote = "Default";
    if(config.oxAdvertiserIds.indexOf(advertiserId) >= 0) {
      inviewTime = config.minTimeOX;
      inviewNote = "OX Programmatic";
    } else if(config.houseAdvertiserIds.indexOf(advertiserId) >= 0) {
      inviewTime = config.minTimeHouse;
      inviewNote = "House";
    } else if(config.adxAdvertiserIds.indexOf(advertiserId) >= 0) {
      inviewTime = config.minTimeADX;
      inviewNote = "AdX";
    }
    return [inviewTime, inviewNote];
  }
  /**
   * Checks slots to figure out which are eligible to refresh and then fires off the call to .auction.
   * This is where the main refresh logic is implemented. This function will be called every 500ms, and may
   * or may not result in an actual ad slot refresh request.
   *
   */
  function refreshSlots() {
    const ts = Date.now();
    const eligibleSlots = [];

    // this will be used if we want to respect roadblocks. if an ad belonging to a LI is active, the whole LI will not be refreshed
    const stickyLineItems = [];

    // first loop on the ad slots to identify:
    // 1.- The slots that can be considered eligible to refresh.
    // 2.- The line items that can be considered ineligible because of roadblock restriction
    //     (if a creative is blocked from refresh, other creatives from same LI are blocked too)

    Object.keys(slotStatus).forEach(slotname => {
      let adSlotEligible = true;
      let lineItemEligible = true;

      if(slotStatus[slotname].refreshTriggered === 1) return; // refresh has already been triggered. Do not refresh again.
      if(config.noRefreshLineIds.indexOf(slotStatus[slotname].lineItemId) >= 0) return; // do not refresh if the lineId is in the list to not refresh
      if(config.noRefreshAdvertiserIds.indexOf(slotStatus[slotname].advertiserId) >= 0) return; // do not refresh if the advertiserId is in the list to not refresh
      if(config.refreshSponsorships === false && config.sponsorshipLineIds.indexOf(slotStatus[slotname].lineItemId) >= 0) return; // do not refresh sponsorships

      const lid = slotStatus[slotname].lineItemId;
      const minDispTime = (lid ? config.minDispTimeDFP : config.minDispTimeADX);
      if (slotStatus[slotname].active || ts - slotStatus[slotname].lastActive < config.minSinceActive) {
        // Ad slot currently or recently active
        adSlotEligible = false;
        // If in roadblock mode, the whole line item will not be eligible for refresh
        lineItemEligible = false;
      } else if (slotStatus[slotname].viewed===false || // ad slot not viewed
        slotStatus[slotname].vieweArea < config.minViewArea || // currently not visible enough
        ts - slotStatus[slotname].loadtime < minDispTime || // not long enough in the page
        ts - slotStatus[slotname].viewed < slotStatus[slotname].reqInviewTime || // not long enough since viewed
        ts - lastUserActivity > config.maxInactive) { // too long since last activity on page (page scroll)
        // Ad slot (and its LI if in roadblock mode) eligible for refresh
        adSlotEligible = false;
      }

      // If the LI is not eligible for refresh, add it to the sticky list
      if (lid && !lineItemEligible && stickyLineItems.indexOf(lid) < 0) {
        stickyLineItems.push(lid);
      }

      if (adSlotEligible) {
        // refreshLogger.logInfo(`refreshing slot ${slotname}`);
        eligibleSlots.push(slotname);
      }
    });

    // Nothing to refresh
    if (eligibleSlots.length === 0) return;

    // This is where the slots that will actually be refreshed will be added
    const refreshList = [];
    // If we are in RESPECT_ROADBLOCKS, we refresh the slots based on their LI eligibility status:
    // (NOT in the list of stickyLineItems)
    if (config.respectRoadblocks) {
      for(let i = 0; i < eligibleSlots.length; i+=1) {
        const li = slotStatus[eligibleSlots[i]].lineItemId;
        if (li == null || stickyLineItems.indexOf(li) < 0) {
          refreshList.push(eligibleSlots[i]);
        }
      }

    } // Not in RESPECT_ROADBLOCKS. Just refresh any slot that qualifies for refresh.
    else {
      for (let i = 0; i < eligibleSlots.length; i+=1) {
        refreshList.push(eligibleSlots[i]);
      }
    }

    // Nothing to refresh
    if (refreshList.length === 0) return;

    // refreshLogger.logInfo(`refreshList ${refreshList}`);

    // Update the refresh count of slots to be refreshed
    for (let i = 0; i < refreshList.length; i+=1) {
      const refreshSlot = refreshList[i];
      // for each slot, update the refresh count
      timesRefreshed[refreshSlot] = (timesRefreshed[refreshSlot] || 0) + 1;
      refreshLogger.logInfo(`Refreshing slot ${refreshSlot} for the alrc: ${timesRefreshed[refreshSlot]}`);
      setTargeting({'alrc': timesRefreshed[refreshSlot]}, refreshSlot);
      slotStatus[refreshSlot].refreshTriggered = 1;
    }

    // refreshLogger.logInfo(`slots to refresh: ${  refreshNames}`);

    // update the line item history custom targeting at a page level.
    // if (lineItemHistory.length > 0) {
    //   googletag.pubads().setTargeting('lih', lineItemHistory);
    // }

    // refresh the ad slots. This will send a new ad request to DFP.
    auction(refreshList);
  }

  /**
   * Method used to track whether an ad slot is active or inactive and how long it's been loaded
   *
   * @param {String} slotname String slotname to subscribe
   * @param {*} unitConfig The unitconfig object for the ad slot
   * @param {*} gptEventObj The GPT event object for the ad slot
   */
  function subscribeSlot(slotname, unitConfig, gptEventObj) {
    const divElement = unitConfig.element;
    const iFrame = divElement.getElementsByTagName("iframe")[0];

    slotStatus[slotname].lineItemId = gptEventObj.lineItemId;
    slotStatus[slotname].advertiserId = gptEventObj.advertiserId;
    slotStatus[slotname].viewed = false;
    slotStatus[slotname].active = false;
    slotStatus[slotname].loadtime = Date.now();
    slotStatus[slotname].refreshTriggered = 0;
    [ slotStatus[slotname].reqInviewTime, slotStatus[slotname].reqInviewNote ] = calcReqInviewTime(gptEventObj.advertiserId);

    if(iFrame) {
      // Attach observers to the ad slot div to be notified when the mouse enters or exists the div area.
      // This will be used to flag an ad slot as active of inactive, which may prevent it from being refreshed.
      iFrame.onmouseover = function admouseover() {
        slotStatus[slotname].active = true;
      }
      iFrame.onmouseout = function admouseout() {
        slotStatus[slotname].active = false;
        slotStatus[slotname].lastActive = Date.now();
      }
    }
    refreshLogger.logInfo('Slot has been rendered: ', slotname, slotStatus[slotname], unitConfig, gptEventObj);
  }

/**
 * Method used to stop monitoring whether an ad slot is active or inactive
 *
 * @param {String} slotname string slotname to unsubscribe from refreshes
 */
  function unsubscribeSlot(slotname, unitConfig) {
    // console.log('unsubscribing slot ' + slotname);
    const divElement = unitConfig.element;
    const iFrame = divElement.getElementsByTagName("iframe")[0];
    delete slotStatus[slotname];
    if(iFrame) {
      iFrame.onmouseover = null;
      iFrame.onmouseout = null;
    }
  }

	/**
	 * Registers the module and sets up hooks around Ad_Rendered, ad_viewable, and ad_visibility_changed events.
   * Also tracks user scroll, mousemove, click and keydown events to detect user activity.
	 *
	 * @private
	 * @memberof AdRefresh
	 */
	function register(){
    getConfig('adrefresh', (newConfig) => {
			config = newConfig;
      if(config.enabled === true) {
        eventEmitter.on(AD_RENDERED, (unitConfig, gptEventObj) => {
          const slotname = gptEventObj.slot.getSlotElementId();
          slotStatus[slotname] = slotStatus[slotname] || {};
          if (gptEventObj.isEmpty) {
            refreshLogger.logInfo(`Slot has been rendered: ${slotname}.  Unfilled blank detected in position ${slotname}. Refreshing will stop for this slot.`,   unitConfig, gptEventObj);
            unsubscribeSlot(slotname, unitConfig);
          } else {
            subscribeSlot(slotname, unitConfig, gptEventObj);
            // add this ad's LI ID to the LI history, if not already there
            if (gptEventObj.lineItemId && lineItemHistory.indexOf(gptEventObj.lineItemId) < 0) {
              lineItemHistory.push(gptEventObj.lineItemId);
            }
          }
          // refreshLogger.atVerbosity(3).logInfo(`SlotRenderEnded for ${slotname}`);
        });
        eventEmitter.on(AD_VISIBILITY_CHANGED, (unitConfig, gptEventObj) => {
          const slotname = gptEventObj.slot.getSlotElementId();
          slotStatus[slotname] = slotStatus[slotname] || {};
          // refreshLogger.logInfo(`slotVisibilityChanged: ${slotname }`, unitConfig, gptEventObj);
          slotStatus[slotname].viewedArea = gptEventObj.inViewPercentage;
        });
        eventEmitter.on(AD_VIEWABLE, (unitConfig, gptEventObj) => {
          const slotname = gptEventObj.slot.getSlotElementId();
          slotStatus[slotname] = slotStatus[slotname] || {};
          // refreshLogger.logInfo(`Slot has been viewed: ${slotname}`, gptEventObj);
          slotStatus[slotname].viewed = Date.now();
        });
        // Update lastUserActivity every time the user activity happens in the page. This will be used to detect inactivity on the page.
        dom().window.onscroll = function scr() {
          lastUserActivity = Date.now();
        }
        dom().window.onmousemove = function mm() {
          lastUserActivity = Date.now();
        }
        dom().window.onkeydown = function kd() {
          lastUserActivity = Date.now();
        }
        dom().window.onclick = function clk() {
          lastUserActivity = Date.now();
        }
      }
		});
  }

	/**
	 * Initializes module
	 *
	 * @private
	 * @memberof AdRefresh
	 */
	function initialize(){
    if(config.enabled === true) {
      refreshLogger.logInfo('Ad Refresh Module Initialized.');
      // this is where the refresh checks are initiated (every x milliseconds)
      refreshTimer = setInterval(refreshSlots, 500);
    } else {
      refreshLogger.logInfo('Ad Refresh Module is disabled via config setting.  AdLib will not refresh ads.');
    }
   //
  }

	/**
	 * Cleans up remnants of module
	 *
	 * @private
	 * @memberof AdRefresh
	 */
	function deregister() {
    // refreshLogger.logInfo('Ad Refresh Module Deregistered.');
    clearInterval(refreshTimer);
  }

	return {
		name: AD_REFRESH,
		register,
		initialize,
    deregister,
	};
})();

export const adRefreshModule = moduleManager.register(adRefreshModuleBase, [ GOOGLE_PUBLISHER_TAG ]);
export default adRefreshModule;
