/* eslint-disable no-underscore-dangle */
import { storage } from "./storage.js";
import GLOBAL_CONFIG from '../../configs/global-config.js';
import { getConfig } from "../config.js";
import CONSTANTS from '../constants.json';
import { eventEmitter } from '../events.js';
import { dom } from "../global.js";
import { getEnvCfg } from "../utilities/environment.js";
import { fetch } from "../utilities/fetch.js";
import defaultsDeep from "../utilities/helpers/defaultsDeep.js";
import errorReplacer from '../utilities/helpers/errorReplacer.js';
import get from "../utilities/helpers/get.js";
import memoize from "../utilities/helpers/memoize.js";
import { bbLogger } from "../utilities/logger.js";

const { ERROR_REPORT } = CONSTANTS.EVENTS;

// Variables we do NOT want changed by configuration
const STATIC_OPTIONS = {
    headers: {
        cat: "$$COMMIT_ACCESS_TOKEN$$",
        version: "rv$bidbarrel.version$",
        variant: "$bidbarrel.variant$"
    }
}

const BEACON_QUERY = {
    cat: "$$COMMIT_ACCESS_TOKEN$$",
    version: "rv$bidbarrel.version$",
    variant: "$bidbarrel.variant$"
}

/**
 * Handles API calls
 *
 * @module api
 * @private
 */
export const api = (function x (){
    /**
     * General configuration for the API service
     *
     * @memberof api
     * @private
     */
    // getConfig doesn't work yet since config is in flux because of remote config.  Only global works.  Need to manually get site+global
    let config = getConfig("api") || GLOBAL_CONFIG.api;
    /**
     * Sets the configurations for this module
     *
     * @memberof api
     * @private
     */
    function register(){
        getConfig("api", (newValue) => {
            config = newValue;
        })
    }
    /**
     * function checker to determine if a url is absolute or not
     *
     * @param {string} [apiPath=''] absolute or relative url path
     * @returns {boolean} whether or not the given api path is absolute
     * @memberof api
     * @private
     */
    function isAbsolute(apiPath = ''){
        return apiPath.indexOf("http") === 0;
    }
    /**
     * Ensures relative paths have a preceding slash
     *
     * @param {string} [apiPath=''] absolute or relative url path
     * @returns {string} adjusted api path
     * @memberof api
     * @private
     */
    function ensureSlash(apiPath = ''){
        if(isAbsolute(apiPath)) return apiPath;
        return apiPath.charAt(0) === "/" ? apiPath : `/${apiPath}`;
    }

    /**
     * Facilitates configuration level expiration configs for API paths and payloads
     *
     * @param {string} apiPath API Path
     * @param {object} definedCacheExpiration Object with expiration configuration
     * @returns {object}
     * @memberof api
     * @private
     */
    function getPathCacheExpiration(apiPath, definedCacheExpiration){
        if(config.cache){
            return config.cache[apiPath] || definedCacheExpiration;
        }
        return definedCacheExpiration
    }
    /**
     * Generates a local storage key for a given api path
     *
     * @param {string} apiPath absolute or relative api url
     * @returns {string} local storage key
     * @memberof api
     * @private
     */
    function _getLsKey(apiPath){
        return `api:${  apiPath
            .replace(/https:\/\//gm, "")
            .replace(/http:\/\//gm, "")
            .replace(/\./gm, "")}`
    }
    /**
     * Memoized function for the above function
     *
     * @memberof api
     * @private
     */
    const getLsKey = memoize(_getLsKey);
    /**
     * Method for attempting to get the cached value for an API path
     *
     * @param {string} apiPath relative or absolute api path
     * @param {object} cacheExpiration expiration configuration object
     * @returns {null|object} cached api response
     * @memberof api
     * @private
     */
    function attemptCacheValue(apiPath, cacheExpiration){
        if(!cacheExpiration) return null;
        const pathCache = getPathCacheExpiration(apiPath, cacheExpiration);
        const lsKey = getLsKey(apiPath);
        return pathCache ? storage.getLocalStorage(lsKey) : null;
    }
    /**
     * Defaults the fetch options for a given fetch call
     *
     * @param {object} opts function call provided options
     * @returns {object}
     * @memberof api
     * @private
     */
    function defaultOptions(opts){
        return defaultsDeep(opts, STATIC_OPTIONS, config.fetchOptions);
    }
    /**
     * Ensures the question mark is applied to the API path
     *
     *
     * @param {string} apiPath relative or absolute api path
     * @returns {string} adjusted api path
     * @memberof api
     * @private
     */
    function addQm(apiPath){
        if(apiPath.indexOf("?") >= 0) {
            return apiPath;
        }
        return `${apiPath}?`;
    }
    /**
     * Applies query params to the url
     *
     * @param {string} apiPath relative or absolute api path
     * @returns {string} adjusted api path
     * @param {object} queryParams single level object with query param key value pairs
     * @memberof api
     * @private
     */
    function applyQueryParams(apiPath, queryParams){
        let qpStr = ''
        if(queryParams && JSON.stringify(queryParams) !== '{}'){
            // eslint-disable-next-line no-restricted-syntax
            for (const key in queryParams) {
                if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
                    const value = queryParams[key];
                    if(qpStr !== ''){
                        qpStr += "&";
                    }
                    if(value && value !== 'null' && value !== 'undefined'){
                        qpStr += `${key}=${value}`;
                    }
                }
            }
        }
        return qpStr !== '' ? addQm(apiPath) + qpStr : apiPath;
    }
      /**
     * Builds the API path with the url
     *
     * @param {string} [apiPath=''] absolute or relative (to api url) path
     * @param {object} [query={}] single level object with query param key value pairs
     * @param {boolean} [withVersion=true] flag to indicate inclusion of api version number in url
     * @returns {string} adjusted api path
     * @memberof api
     * @private
     */
      function buildUrl(apiPath = '', query = {}, withVersion = true){
        if(isAbsolute(apiPath)) {
          return applyQueryParams(apiPath, query);
        }
        return applyQueryParams(getEnvCfg(config.apiBase) + (withVersion ? `/v${config.version || '1'}` : '') + apiPath, query);
    }
    /**
     * Method for providing a manner in which to abort a api call mid flight
     *
     * @returns {AbortController|object} abort controller or stub(if not supported)
     * @memberof api
     * @private
     */
    function createAbort(){
        if(!dom().window.AbortController) return {signal: undefined, abort: () => false};
        // eslint-disable-next-line no-undef
        return new AbortController;
    }
    /**
     * Underlying fetch method that handles all business logic
     *
     * @param {string} apiPath absolute or relative api url
     * @param {object} options Custom API Fetch Options
     * @returns {Promise<Object>} A promise that ultimately returns the API response body
     * @memberof api
     * @private
     */
    async function _fetch(apiPath, options){
        // eslint-disable-next-line prefer-const
        let {expires, useVersion, timeout, abortHandler, query, ...opts} = defaultOptions(options);
        const _apiPath = ensureSlash(apiPath);
        expires = getPathCacheExpiration(_apiPath, expires);
        if(opts.method === "GET" && expires){
            const existingValue = attemptCacheValue(_apiPath, expires);
            if(existingValue) return existingValue;
        }
        const url = buildUrl(_apiPath, query, useVersion);
        let listeningTimeout;
        if(timeout > 0){
            const handler = abortHandler || createAbort();
            listeningTimeout = setTimeout(() => handler.abort(), timeout);
            opts.signal = handler.signal;
        }
        try {
            const response = await fetch(url, opts);
            if(listeningTimeout) clearTimeout(listeningTimeout);
            if(get(opts, 'headers.Accept') !== "application/json") return response;
            const jsonResponse = await response.json();
            if(opts.method === "GET"){
                const key = getLsKey(_apiPath);
                const value = jsonResponse;
                if(expires && (response.status >= 200 && response.status < 300)){
                    storage.setLocalStorage(key, value, {expires})
                }
            }
            bbLogger.atVerbosity(5).logInfo(`API Request Success. url=${url}`, options, jsonResponse);
            return {_response: response, ...jsonResponse};
        } catch (err) {
            bbLogger.logError(`API call failed. url=${url}`);
            const errorObj = new Error(`API call failed. url=${url}. ${JSON.stringify(err,errorReplacer)}`);
            eventEmitter.emit(ERROR_REPORT, errorObj);
            return {success: false, status: "error", error: err};
        }
    }
    /**
     * Bootstrapping function for exposed api composition
     *
     * @param {string} method HTTP Method to default to
     * @returns {Function} wrapper method with http method applied
     * @memberof api
     * @private
     */
    function _fetchWithMethod(method){
        return (apiPath, opts) => _fetch(apiPath, {method: method.toUpperCase(), ...opts })
    }
    /**
     * Method to determine if apiPath is an ad library api path
     *
     * @param {String} apiPath
     * @memberof api
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    function isAdLibApi(apiPath){
        return !isAbsolute(apiPath) || apiPath.indexOf(getEnvCfg(config.apiBase)) >= 0;
    }
    /**
     * Beacon method to facilitate a form of fetch where we do not care about a response
     *
     * This method is much more performant than normal fetch provided you do not care to read a response
     *
     * The payload limitation for this method is a size of 64kb and potentnially lower in some browsers so please keep the payload size small with this method
     *
     * @param {string} apiPath relative or absolute api path
     * @param {object} payload data to send with beacon
     * @returns {boolean} flag to indicate successful queueing. true=success, false=fail
     * @memberof api
     * @private
     */
    function sendBeacon(apiPath, payload){
        if(dom().window.navigator.sendBeacon){
            // const url = isAdLibApi(apiPath) ? buildUrl(ensureSlash(apiPath), BEACON_QUERY) : buildUrl(ensureSlash(apiPath), BEACON_QUERY); // MLS: sendBeacon is not used elsewhere.
            const url = buildUrl(ensureSlash(apiPath), BEACON_QUERY); // MLS: passing query to nonapi path because there's two.. fix when there's one
            const data = typeof payload === "string" ? payload : JSON.stringify(payload);
            const wasQueued = dom().window.navigator.sendBeacon(url, data);
            if(wasQueued){
                bbLogger.atVerbosity(5).logInfo(`Sent Beacon Data. url=${url}`, data);
            }
            return wasQueued;
        }
        return false;
    }
    /**
     * Function to manually override the config used for the API
     * @param {*} configArg config to set for api
     */
    function setConfig(configArg){
        config = configArg;
    }
    // Go ahead and register this service
    register();
    return {
        get: _fetchWithMethod("GET"),
        post: _fetchWithMethod("POST"),
        put: _fetchWithMethod("PUT"),
        delete: _fetchWithMethod("DELETE"),
        fetch: _fetch,
        getUrl: (apiPath, query, withVersion = true) => buildUrl(ensureSlash(apiPath), query, withVersion),
        sendBeacon,
        createAbort,
        setConfig
    }
})();
export default api;
